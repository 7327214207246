import {createSlice} from '@reduxjs/toolkit';

export interface ReduxStateIF {
  user: any;
  userLoaded: boolean;
  numbers: {
    credits: number;
    generated: number;
    latestTransaction: string;
    paid: boolean;
    refCode: string;
    refCount: number;
    verified: boolean | null;
  };
  notification: {
    token: string;
    permissions: boolean;
  };
  deviceNumbers: {
    credits: number;
    generated: number;
  };
  status: {
    generated: number;
    queueCount: number;
    queueTime: number;
    messages: any[];
    services: any[];
    isAnyActive: boolean;
  };
  settings: {
    vibration: boolean;
    colorScheme: boolean;
    startScreen: boolean;
  };
  message: {
    message: string;
    error: boolean;
    link: string | undefined;
    open: boolean;
    duration: number | null;
    internalLink: boolean;
    storageName: string;
    storageData: any;
    storageLink: string;
    background: string;
    messageColor: string;
    linkColor: string;
  },
}

const initialState: ReduxStateIF = {
  user: null,
  userLoaded: false,
  numbers: {
    credits: 0,
    generated: 0,
    latestTransaction: '',
    paid: false,
    refCode: '',
    refCount: 0,
    verified: null,
  },
  deviceNumbers: {
    credits: 0,
    generated: 0,
  },
  status: {
    generated: 0,
    queueCount: 0,
    queueTime: 0,
    messages: [],
    services: [],
    isAnyActive: false,
  },
  notification: {
    token: '',
    permissions: false,
  },
  settings: {
    vibration: true,
    colorScheme: true,
    startScreen: false,
  },
  message: {
    message: "",
    error: false,
    link: undefined,
    open: false,
    duration: 8000,
    internalLink: false,
    storageName: "",
    storageData: undefined,
    storageLink: "",
    background: "#333",
    messageColor: "white",
    linkColor: "#A4BDCE",
  },
};

export const reduxSplice = createSlice({
  name: 'redux',
  initialState,

  reducers: {
    setUserState: (state, action) => {
      state.user = action.payload;
      state.userLoaded = true;
    },
    setNumbersState: (state, action) => {
      state.numbers = {...state.numbers, ...action.payload};
    },
    setDeviceNumbersState: (state, action) => {
      state.deviceNumbers = {...state.deviceNumbers, ...action.payload};
    },
    setStatusState: (state, action) => {
      console.log({...state.status, ...action.payload})
      state.status = {...state.status, ...action.payload};
    },
    setNotificationState: (state, action) => {
      state.notification = {...state.notification, ...action.payload};
    },
    setSettingsState: (state, action) => {
      state.settings = {...state.settings, ...action.payload};
    },
     setMessageState: (state, action) => {
      state.message = {...state.message, ...action.payload};
    },
  },
});

export const {
  setUserState,
  setNumbersState,
  setDeviceNumbersState,
  setStatusState,
  setNotificationState,
  setSettingsState,
  setMessageState,
} = reduxSplice.actions;

export default reduxSplice.reducer;
