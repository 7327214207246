import React, { useState, useEffect } from "react";
import { firebaseDB } from "../firebase/firebase";
import { ref, onValue } from "firebase/database";
import { onAuthStateChanged } from "firebase/auth";
import { useAppSelector, useAppDispatch } from "../redux/reduxHooks";
import { setStatusState, setUserState } from "../redux/reduxSlice";
import { firebaseAuth } from "../firebase/firebase";

export default function AuthListeners(): JSX.Element {
    const userState = useAppSelector((state: any) => state.redux.user);
    const numbersState = useAppSelector((state: any) => state.redux.numbers);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return onAuthStateChanged(firebaseAuth, (user) => {
       console.log(user)
      if (user) {
        dispatch(setUserState(user));
        // ...
      } else {
        dispatch(setUserState(null));
      }
    });
  }, []);

  const handleVerification = async () => {
    if (userState === null) {
      return;
    }
    try {
      const getIdToken = await userState.getIdToken();
      const tempObj = {
        credentials: getIdToken,
        uid: userState.uid,
      };
      const fetchVerification = await fetch(
        'https://us-central1-portraiture-app.cloudfunctions.net/auth-verified',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(tempObj),
        },
      );
      const json = await fetchVerification.json();
    } catch (error: any) {
      console.log('Error');
      console.log(error.message);
    }
  };
  useEffect(() => {
    if (userState === null) {
      return;
    }
    if (userState.emailVerified === true && numbersState.verified === false) {
      handleVerification();
    }
  }, [userState, numbersState.verified]);

  return <></>;
}
