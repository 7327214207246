import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { firebaseAuth, firestore } from "../../../components/firebase/firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updatePassword,
  deleteUser,
} from "firebase/auth";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../components/redux/reduxHooks";
import { setMessageState } from "../../../components/redux/reduxSlice";
import "./auth.css";
import "../settings.css";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function DeleteUser() {
  const userState = useAppSelector((state: any) => state.redux.user);
  const messageState = useAppSelector((state: any) => state.redux.message);
  const [userLoading, setUserLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [dataOpen, setDataOpen] = useState(false);
  const [userOpen, setUserOpen] = useState(false);
  const [message, setMessage] = useState("");
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const onDeleteData = async () => {
    setDataLoading(true);
    setDataOpen(false);
    try {
      const allActiveDocsRef = collection(
        firestore,
        "generated",
        userState.uid,
        "userImages"
      );
      const allActiveDocsSnap = await getDocs(allActiveDocsRef);
      allActiveDocsSnap.forEach((doc) => {
        console.log(doc.ref);
        deleteDoc(doc.ref);
      });
      const allGeneratedDocsRef = collection(
        firestore,
        "generated",
        userState.uid,
        "generatedImages"
      );
      const allGeneratedDocsSnap = await getDocs(allGeneratedDocsRef);
      allGeneratedDocsSnap.forEach((doc) => {
        console.log(doc.ref);
        deleteDoc(doc.ref);
      });
      dispatch(
        setMessageState({
          ...messageState,
          open: true,
          message: "All data deleted successfully.",
          duration: 5000,
          background: "#222",
          messageColor: "#fff",
        })
      );
      return navigation("/settings/account");
    } catch (error: any) {
      if (error.code === "auth/requires-recent-login") {
        setDataLoading(false);
        setMessage(
          "This operation is sensitive and requires recent authentication. Please login again to update your password."
        );
        return console.log(error.message);
      } else {
        setDataLoading(false);
        setMessage("Something went wrong. Please try again.");
        return console.log(error.message);
      }
    }
  };

  const onDeleteUser = async () => {
    if (userState === null) {
      return;
    }
    setUserLoading(true);
    setUserOpen(false);
    try {
      await deleteUser(userState);
      dispatch(
        setMessageState({
          ...messageState,
          open: true,
          message: "Account deleted successfully.",
          duration: 5000,
          background: "#222",
          messageColor: "#fff",
        })
      );
      return navigation("/settings");
    } catch (error: any) {
      if (error.code === "auth/requires-recent-login") {
        setUserLoading(false);
        setMessage(
          "This operation is sensitive and requires recent authentication. Please login again to update your password."
        );
        return console.log(error.message);
      } else {
        setUserLoading(false);
        setMessage("Something went wrong. Please try again.");
        return console.log(error.message);
      }
    }
  };

  return (
    <div className="Login">
      <div className="SettingsHeader">
        <p
          className="LoginMessage"
          style={{ fontSize: "150%", fontWeight: "600" }}
        >
          WARNING!
        </p>
      </div>
      <p
        className="LoginMessage"
        style={{ marginTop: "-15px", marginBottom: "-5px" }}
      >
        Your account, credits and all of your data will be deleted from
        Portraiture.ai!
      </p>
      <p className="LoginMessage" style={{ marginBottom: "30px" }}>
        You can also choose to only delete your data and keep your account and
        credits.
      </p>

      <div className="LoginEmail">
        <Button
          sx={{
            width: "100%",
            marginTop: "16px",
            marginBottom: "16px",
            backgroundColor: "#333",
            color: "#fff",
            border: "none",
          }}
          variant="outlined"
          color="inherit"
          onClick={() => setDataOpen(true)}
          disabled={dataLoading}
        >
          {dataLoading === true ? (
            <CircularProgress size={22} sx={{ color: "white" }} />
          ) : (
            "DELETE DATA"
          )}
        </Button>
        <p className="LoginMessage" style={{ fontSize: "110%" }}>
          or
        </p>
        <Button
          sx={{
            width: "100%",
            marginTop: "16px",
            marginBottom: "16px",
            backgroundColor: "#f44336",
            color: "#fff",
            border: "none",
          }}
          variant="outlined"
          color="inherit"
          onClick={() => setUserOpen(true)}
          disabled={userLoading}
        >
          {userLoading === true ? (
            <CircularProgress size={22} color="inherit" />
          ) : (
            "DELETE USER"
          )}
        </Button>
        <p className="LoginMessage" style={{ marginBottom: "30px" }}>
          {message}
        </p>
      </div>
      <Dialog
        open={dataOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setDataOpen(false)}
        sx={{
          "& .MuiDialog-paper": { backgroundColor: "#222", color: "#fff" },
        }}
      >
        <DialogTitle>Continue deleting all data?</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "#fff" }}>
            All of your generated content will be deleted! This action cannot be
            undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={() => setDataOpen(false)}>
            CANCEL
          </Button>
          <Button color="error" onClick={onDeleteData}>
            DELETE DATA
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={userOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setUserOpen(false)}
        sx={{
          "& .MuiDialog-paper": { backgroundColor: "#222", color: "#fff" },
        }}
      >
        <DialogTitle>Continue removing account?</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: "#fff" }}>
            All of your generated content, tokens and account will be deleted!
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={() => setUserOpen(false)}>
            CANCEL
          </Button>
          <Button color="error" onClick={onDeleteUser}>
            REMOVE ACCOUNT
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DeleteUser;
