import React, { useState, useEffect } from "react";
import { firebaseDB } from "../firebase/firebase";
import { ref, onValue } from "firebase/database";
import { onAuthStateChanged } from "firebase/auth";
import { useAppSelector, useAppDispatch } from "../redux/reduxHooks";
import {
  setStatusState,
  setUserState,
  setNumbersState,
} from "../redux/reduxSlice";
import { firebaseAuth } from "../firebase/firebase";

export default function FirebaseListeners(): JSX.Element {
  const userState = useAppSelector((state: any) => state.redux.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const statusRef = ref(firebaseDB, "/public/status/services");
    return onValue(statusRef, (snapshot) => {
      const tempVal = snapshot.val();
      if (tempVal === null || tempVal === undefined) {
        return;
      }
      const tempArray: any = Object.values(tempVal);
      let servicesOnCount = 0;
      let totalQueueCount = 0;
      let totalQueueTime = 0;
      let isAnyActive = false;

      for (let i = 0; i < tempArray.length; i++) {
        if (
          tempArray[i].manualOff === false &&
          tempArray[i].autoOff === false
        ) {
          servicesOnCount += 1;
          totalQueueCount += tempArray[i].queueCount;
          totalQueueTime += tempArray[i].queueTime * tempArray[i].queueCount;
        }
        if (
          tempArray[i].manualOff === false &&
          tempArray[i].autoOff === false &&
          tempArray[i].queueCount < tempArray[i].queueLimit
        ) {
          isAnyActive = true;
        }
      }

      dispatch(
        setStatusState({
          services: tempArray,
          queueTime: Math.floor(totalQueueTime / servicesOnCount),
          queueCount: Math.floor(totalQueueCount / servicesOnCount),
          isAnyActive: isAnyActive,
        })
      );
    });
  }, []);

  useEffect(() => {
    const statusRef = ref(firebaseDB, "/public/status/generated");
    return onValue(statusRef, (snapshot) => {
      const tempVal = snapshot.val();
      if (tempVal === null || tempVal === undefined) {
        return;
      }
      dispatch(setStatusState({ generated: tempVal }));
    });
  }, []);

  useEffect(() => {
    if (userState === null) {
      return;
    }
    const statusRef = ref(firebaseDB, `/public/credits/${userState.uid}`);
    return onValue(statusRef, (snapshot) => {
      const tempVal = snapshot.val();
      if (tempVal === null || tempVal === undefined) {
        return;
      }
      dispatch(
        setNumbersState({
          credits: tempVal.credits,
          generated: tempVal.generated,
          paid: tempVal.paid,
          refCode: tempVal.refCode,
          refCount: tempVal.refCount,
          verified: tempVal.verified ? tempVal.verified : false,
        })
      );
    });
  }, [userState]);

  useEffect(() => {
    const statusRef = ref(firebaseDB, "/public/status/messages");
    return onValue(statusRef, (snapshot) => {
      let tempSnackArr: any = [];
      const tempVal = snapshot.val();
      console.log(tempVal);
      if (tempVal !== undefined) {
        const snackArr: any = Object.entries(tempVal);
        for (let i = 0; i < snackArr.length; i++) {
          const tempSnackObj = {
            id: snackArr[i][1].id,
            text: snackArr[i][1].text,
            duration: snackArr[i][1].duration,
            textColor: snackArr[i][1].textColor,
            backgroundColor: snackArr[i][1].backgroundColor,
            button: snackArr[i][1].button,
            multiple: snackArr[i][1].multiple,
            buttonColor: snackArr[i][1].buttonColor,
            buttonText: snackArr[i][1].buttonText,
          };
          tempSnackArr.push(tempSnackObj);
        }
      }
      dispatch(setStatusState({ messages: tempSnackArr }));
    });
  }, []);

  return <></>;
}
