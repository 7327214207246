import React, { useState, useEffect } from "react";
import PortraitureIcon from "../../../components/ui/PortraitureIcon";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { firebaseAuth } from "../../../components/firebase/firebase";
import {sendPasswordResetEmail} from "firebase/auth";
import { useAppSelector } from "../../../components/redux/reduxHooks";
import "./auth.css";
import "../settings.css";

function ResetPassword() {
    const userState = useAppSelector((state: any) => state.redux.user);
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [message, setMessage] = useState(
    "Enter the email address for the account you want to reset the password for. We'll send you a link to reset your password.",
  );
  const [messageEmail, setMessageEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const navigation = useNavigate();

  const validateEmail = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(emailInput)) {
      setMessageEmail(true);
    } else {
      setMessageEmail(false);
    }
  };

  useEffect(() => {
    validateEmail();
  }, [emailInput]);

  const initReset = async () => {
    setEmailLoading(true);
    if (messageEmail === true) {
      setMessage('Please enter a valid email address.');
      setEmailLoading(false);
      return;
    }
    try {
      const initReset = await sendPasswordResetEmail(firebaseAuth, emailInput);
      console.log("success");
      return setEmailLoading(false);
    } catch (error: any) {
      console.log(error.code);
      if (error.code === 'auth/user-not-found') {
        setMessage('No user found with this email address.');
      } else {
        setMessage('Something went wrong. Please try again.');
      }
      return setEmailLoading(false);
    }
  };

  useEffect(() => {
    if (userState !== null && userState !== undefined) {
      navigation("/settings");
    }
  }, [userState]);

  return (
    <div className="Login">
      <div className="SettingsHeader">
        <PortraitureIcon color="#333" />
      </div>
      <p className="LoginMessage">{message}</p>
      <div className="LoginEmail">
        <TextField
          sx={textfieldStyle}
          label="Email"
          variant="outlined"
          value={emailInput}
          onChange={(event) => setEmailInput(event.target.value)}
          size="small"
          type="email"
        />
        <Button
          sx={{
            width: "100%",
          }}
          variant="outlined"
          color="inherit"
          disabled={messageEmail === true}
            onClick={initReset}
        >
         {emailLoading === true ? (
            <CircularProgress size={22} color="inherit" />
          ) : (
            "Reset Password"
          )}
        </Button>
      </div>
      
    </div>
  );
}

const textfieldStyle = {
  marginBottom: "16px",
  color: "#333",
  width: "100%",
  borderColor: "#333",
  justifySelf: "center",
  opacity: 0.8,
  "& label.Mui-focused": {
    color: "#333",
    borderColor: "#333",
  },
  "& .Mui-disabled": {
    color: "#333",
    borderColor: "#333",
  },
  "& .MuiInputLabel-root.Mui-disabled": {
    color: "#333",
  },
  "& .MuiOutlinedInput-root.Mui-disabled": {
    "& fieldset": {
      borderColor: "#333",
    },
    "& input": {
      borderColor: "#333",
      WebkitTextFillColor: "unset",
    },
    "& label": {
      color: "#333",
    },
    color: "#333",
    borderColor: "#333",
  },
  "& label": {
    color: "#333",
    borderColor: "#333",
  },
  "& textarea": {
    color: "#333",
    borderColor: "#333",
    width: "95%",
  },
  "& input": {
    color: "#333",
    borderColor: "#333",
  },
  "& .MuiInput-underline:after": {
    borderColor: "#333",
    color: "#333",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#333",
      color: "#333",
    },
    "&:hover fieldset": {
      borderColor: "#333",
      color: "#333",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#333",
      color: "#333",
    },
  },
  "@media (max-width: 800px)": {
    width: "100%",
  },
};

export default ResetPassword;
