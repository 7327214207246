import React from "react";
import NavigationTab from "./components/ui/NavigationTab";
import Generator from "./views/generator/Generator";
import Gallery from "./views/gallery/Gallery";
import Settings from "./views/settings/Settings";
import Login from "./views/settings/auth/Login";
import Signup from "./views/settings/auth/Signup";
import ResetPassword from "./views/settings/auth/ResetPassword";
import SettingsPage from "./views/settings/settings/SettingsPage";
import { CircularProgress } from "@mui/material";
import { useAppSelector } from "./components/redux/reduxHooks";

import FirebaseListeners from "./components/listeners/FirebaseListeners";
import AuthListeners from "./components/listeners/AuthListeners";
import LocalStorageListeners from "./components/listeners/LocalStorageListener";
import SnackbarTemplate from "./components/ui/Snackbar";
import MessageListeners from "./components/listeners/MessageListeners";
import Learn from "./views/settings/learn/Learn";
import Tokens from "./views/settings/tokens/Tokens";
import Privacy from "./views/settings/privacy/Privacy";
import Terms from "./views/settings/terms/Terms";
import Faq from "./views/settings/faq/Faq";
import HowMenu from "./views/settings/how/HowMenu";
import UpdatePassword from "./views/settings/auth/UpdatePassword";
import DeleteUser from "./views/settings/auth/DeleteUser";
import GalleryItem from "./views/gallery/GalleryItem";

import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import "./App.css";

function App() {
  const userLoadedState = useAppSelector(
    (state: any) => state.redux.userLoaded
  );
  return (
    <div className="App">
      {userLoadedState ? (
        <div className="App">
          <Routes>
            <Route path="/" element={<Generator />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/gallery/:imageID" element={<GalleryItem />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/settings/login" element={<Login />} />
            <Route path="/settings/register" element={<Signup />} />
            <Route path="/settings/reset" element={<ResetPassword />} />
            <Route path="/settings/update" element={<UpdatePassword />} />
            <Route path="/settings/delete" element={<DeleteUser />} />
            <Route path="/settings/account" element={<SettingsPage />} />
            <Route path="/settings/how" element={<HowMenu />} />
            <Route path="/settings/how/learn" element={<Learn />} />
            <Route path="/settings/how/tokens" element={<Tokens />} />
            <Route path="/settings/how/faq" element={<Faq />} />
            <Route path="/settings/privacy" element={<Privacy />} />
            <Route path="/settings/terms" element={<Terms />} />
            <Route path="*" element={<Generator />} />
          </Routes>
          <NavigationTab />
          <div className="BottomSocialLinksInner">
            <a href="https://portraiture.ai" target="_blank" className="BottomSocialLinkAlt" rel="noreferrer">© 2023 Portraiture.ai</a>
        </div>
        </div>
      ) : (
        <CircularProgress color="inherit" sx={{ fontSize: "200%" }} />
      )}
      <AuthListeners />
      <FirebaseListeners />
      <LocalStorageListeners />
      <SnackbarTemplate />
      <MessageListeners />
    </div>
  );
}

export default App;
