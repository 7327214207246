import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../redux/reduxHooks";
import { setSettingsState, setMessageState } from "../redux/reduxSlice";

export default function LocalStorageListeners(): JSX.Element {
  const settingsState = useAppSelector((state) => state.redux.settings);
  const messageState = useAppSelector((state) => state.redux.message);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const getData = async () => {
      try {
        const jsonValue = localStorage.getItem("gdpr");
        console.log(jsonValue);
        if (jsonValue === null) {
          if (messageState.open === false) {
            dispatch(
              setMessageState({
                ...messageState,
                open: true,
                message:
                  "Welcome to Portraiture! We use cookies to ensure that we give you the best experience on our website. By continuing to use our site, you consent to our use of cookies and other similar technologies.",
                storageName: "gdpr",
                storageData: true,
                storageLink: "OK",
                duration: null,
              })
            );
          }
        }
      } catch (e) {
        console.log(e);
      }
    };
    getData();
  }, [messageState.open]);

  return <></>;
}
