import React from "react";
import "./learn.css";
import "../../../App.css";

const listData = [
  {
    title: "Text Prompt",
    subtitle: "Generate an image from text, add a prompt or use the templates.",
    paragraphs: [
      {
        title: "Tips for Portraiture.ai prompts",
        text: [
          [
            "Use something well-known - ",
            "Dragon, knight, wizard, king, queen, etc.",
          ],
          [
            "Be as specific as possible - ",
            "A rainbow-colored butterfly flying across a field of flowers during a sunset.",
          ],
          [
            "Use multiple adjectives - ",
            "Beautiful, colorful, powerful, intricate, detailed, massive.",
          ],
          [
            "Include the name of an artist (to invoke their art style) - ",
            "Vincent Van Gogh, Picasso, Salvador Dali, M.C. Escher.",
          ],
          [
            "Describe a style - ",
            "Surrealism, Cyberpunk, Realism, Cubism, Contemporary, Fantasy, Abstract, Modern, Impressionism, Minimal.",
          ],
          [
            "Include computer graphics - ",
            "Octane render, Unreal Engine, Ray tracing.",
          ],
        ],
      },
    ],
    list: {
      title: "Examples",
      items: [
        {
          title:
            "A beautiful painting of a singular lighthouse, shining its light across a tumultuous sea of blood by greg rutkowski and thomas kinkade, Trending on artstation.",
          subtitle: "Text Attention: 7 - Seed: 1525493345",
          url: require("../../../static/img/prompt1.jpg"),
        },
        {
          title:
            "Beautiful butterfly anatomy diagram, bold shūji, chart, schematics, infographic, scientific, measurements, abstract, surreal, collage, new media design, poster, colorful highlights, 8k, extremely detailed, style of Katsuhiro Otomo + Masamune Shirow",
          subtitle: "Text Attention: 7 - Seed: 1093650417",
          url: require("../../../static/img/prompt4.jpg"),
        },
        {
          title:
            "Portrait photo of a asia old warrior chief, tribal panther make up, blue on red, side profile, looking away, serious eyes, 50mm portrait photography, hard rim lighting",
          subtitle: "Text Attention: 7 - Seed: 123467526",
          url: require("../../../static/img/prompt2.jpg"),
        },
        {
          title:
            "A wholesome animation key shot of a band behemoth performing on stage, medium shot, studio ghibli, pixar and disney animation, 3 d, sharp, rendered in unreal engine 5, anime key art by greg rutkowski, bloom, dramatic lighting",
          subtitle: "Text Attention: 7 - Seed: 1904419170",
          url: require("../../../static/img/prompt3.jpg"),
        },
        {
          title:
            "A landscape by simon stalenhag of a very large realistic highly detailed imposing robotic mechanical cat, stranded alone and roaming in the chaos across a depressing abandoned post – apocalyptic landscape, post – apocalyptic corrupted themes, artstation trending",
          subtitle: "Text Attention: 7 - Seed: 645819319",
          url: require("../../../static/img/prompt5.jpg"),
        },
      ],
    },
    end: [],
  },
  {
    title: "Seed",
    subtitle:
      "Leave as is at 0 for a random seed or set your own seed to generate different results. Use the same seed to generate similar looking images.",
    paragraphs: [],
    list: {
      title: "Examples",
      items: [
        {
          title: "Seed: 1604146844",
          subtitle: "",
          url: require("../../../static/img/seed1.jpg"),
        },
        {
          title: "Seed: 3251891897",
          subtitle: "",
          url: require("../../../static/img/seed2.jpg"),
        },
        {
          title: "Seed: 3887196228",
          subtitle: "",
          url: require("../../../static/img/seed3.jpg"),
        },
        {
          title: "Seed: 2368248337",
          subtitle: "",
          url: require("../../../static/img/seed4.jpg"),
        },
      ],
    },
    end: [
      "The example images are generated with the prompt: ",
      "A trail through the unknown, atmospheric, hyper realistic, 8k, epic composition, cinematic, octane render, 16K resolution, Landscape veduta photo by Dustin Lefevre & tdraw, DeviantArt, rendered in Enscape, Breath of The Wild, 4k detailed post processing, artstation, rendering by octane, unreal engine",
    ],
  },
  {
    title: "Text Attention",
    subtitle:
      "How strict to the text prompts should the AI behave? Scale for how strongly the image should conform to prompts, lower values produce more creative results.",
    paragraphs: [],
    list: {
      title: "Examples",
      items: [
        {
          title: "Text Attention: 20",
          subtitle: "",
          url: require("../../../static/img/text1.jpg"),
        },
        {
          title: "Text Attention: 10",
          subtitle: "",
          url: require("../../../static/img/text2.jpg"),
        },
        {
          title: "Text Attention: 1",
          subtitle: "",
          url: require("../../../static/img/text3.jpg"),
        },
      ],
    },
    end: [
      "The example images are generated with the prompt & seed: ",
      "Interior design, open plan, kitchen and living room, modular furniture with cotton textiles, wooden floor, high ceiling, large steel windows viewing a city - 2284752158",
    ],
  },
  {
    title: "Image Attention",
    subtitle:
      "How strict to the image picked by user should the AI behave? Lower values produce more creative results.",
    paragraphs: [],
    list: {
      title: "Examples",
      items: [
        {
          title: "Original Image",
          subtitle: "",
          url: require("../../../static/img/image0.jpg"),
        },
        {
          title: "Image Attention: 20",
          subtitle: "",
          url: require("../../../static/img/image1.jpg"),
        },
        {
          title: "Image Attention: 10",
          subtitle: "",
          url: require("../../../static/img/image2.jpg"),
        },
        {
          title: "Image Attention: 1",
          subtitle: "",
          url: require("../../../static/img/image3.jpg"),
        },
      ],
    },
    end: [
      "The example images are generated with the prompt & seed: ",
      "Complex 3 d render, hyper detailed, ultrasharp, cyberpunk android street samurai, digital portrait, concept art, illustration, natural soft rim light, anatomical, facial muscles, elegant, regal, hyper realistic, ultra detailed, techwear clothing, octane render, darriel diano style, volumetric lighting, 8 k post–production, unreal engine 5, unity engine - 2848019447",
    ],
  },
  {
    title: "Ai Models",
    subtitle:
      "Which model should be used to generate the image? The models are trained on different datasets and produce different styles and results.",
    paragraphs: [],
    list: {
      title: "Models",
      items: [
        {
          title: "Standard Model",
          subtitle: "",
          url: require("../../../static/img/model1.jpg"),
        },
        {
          title: "Anime Model (Coming Soon)",
          subtitle: "",
          url: require("../../../static/img/model2.jpg"),
        },
      ],
    },
    end: [
      "The example images are generated with the prompt & seed: ",
      "portrait photo headshot by mucha, sharp focus, elegant, render, octane, detailed, award winning photography, masterpiece, rim lit - 2593314067",
    ],
  },
];

const proccessorInfo = {
  title: "Image Proccessors",
  subtitle:
    "What kind of proccessor will be used to analyze the image from user? Each proccessor has different capabilities and produces different results.",
  list: {
    title: "Proccessors",
    items: [
      {
        title: "Depth Map",
        subtitle: "Analyzes the depth of the image and creates a depth map.",
        url: require("../../../static/img/depth.jpg"),
      },
      {
        title: "Outlines",
        subtitle: "Finds the edges in the image and creates outlines.",
        url: require("../../../static/img/outlines.jpg"),
      },
      {
        title: "3d Map",
        subtitle: "Creates a 3d map of the image.",
        url: require("../../../static/img/3d.jpg"),
      },
      {
        title: "Photostat",
        subtitle: "Creates a photostatic copy type of the image.",
        url: require("../../../static/img/photostat.jpg"),
      },
    ],
  },
  end: [
    "The example images are generated with the prompt & seed: ",
    "Complex 3 d render, hyper detailed, ultrasharp, cyberpunk android street samurai, digital portrait, concept art, illustration, natural soft rim light, anatomical, facial muscles, elegant, regal, hyper realistic, ultra detailed, techwear clothing, octane render, darriel diano style, volumetric lighting, 8 k post–production, unreal engine 5, unity engine - 2848019447",
  ],
};

function Learn() {
  return (
    <div className="TemplateOuter">
      <div className="Learn">
        {listData.map((item, index) => (
          <div className="LearnItemOuter" key={index}>
            <div className="LearnDivider"></div>
            <div className="LearnItem">
              <p className="LearnItemTitle">{item.title}</p>
              <p className="LearnItemSubtitle">{item.subtitle}</p>
              {item.list.title !== "" ? (
                <p className="LearnItemListTitle">{item.list.title}</p>
              ) : null}
              <div className="LearnItemList">
                {item.list.items.map((item2, index2) => (
                  <div className="LearnItemListItem" key={index2}>
                    <img
                      src={item2.url}
                      alt=""
                      className="LearnItemListImage"
                    />
                    <p className="LearnItemListItemTitle">{item2.title}</p>
                    {item2.subtitle !== "" ? (
                      <p className="LearnItemListItemSubtitle">
                        {item2.subtitle}
                      </p>
                    ) : null}
                  </div>
                ))}
              </div>
              {item.paragraphs.length > 0
                ? item.paragraphs.map((paragraph: any, index3: number) => (
                    <div key={index3}>
                      <p className="LearnItemListTitle">{paragraph.title}</p>
                      {paragraph.text.map(
                        (text: string[], indexEnd: number) => (
                          <div key={indexEnd}>
                            <p className="LearnItemParagraph">
                              <b>{text[0]}</b>
                              {text[1]}
                            </p>
                          </div>
                        )
                      )}
                    </div>
                  ))
                : null}
              {item.end.length > 0 ? (
                <p className="LearnItemParagraph">
                  <b>{item.end[0]}</b>
                  {item.end[1]}
                </p>
              ) : null}
            </div>
          </div>
        ))}
        <div className="LearnItemOuter">
            <div className="LearnDivider"></div>
            <div className="LearnItem">
              <p className="LearnItemTitle">{proccessorInfo.title}</p>
              <p className="LearnItemSubtitle">{proccessorInfo.subtitle}</p>
              {proccessorInfo.list.title !== "" ? (
                <p className="LearnItemListTitle">{proccessorInfo.list.title}</p>
              ) : null}
              <div className="LearnItemListWide">
                {proccessorInfo.list.items.map((item2, index2) => (
                  <div className="LearnItemListItemWide" key={index2}>
                    <img
                      src={item2.url}
                      alt=""
                      className="LearnItemListImage"
                    />
                    <p className="LearnItemListItemSubtitle">{item2.title}</p>
                    {item2.subtitle !== "" ? (
                      <p className="LearnItemListItemTitle">
                        {item2.subtitle}
                      </p>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}

export default Learn;
