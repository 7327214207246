export const profanityWords: string[] = [
  '2 girls 1 cup',
  '2g1c',
  '4r5e',
  '5h1t',
  '5hit',
  'a55',
  'a_s_s',
  'acrotomophilia',
  'alabama hot pocket',
  'alaskan pipeline',
  'anal',
  'anilingus',
  'anus',
  'apeshit',
  'ar5e',
  'arrse',
  'arse',
  'arsehole',
  'ass',
  'ass-fucker',
  'ass-hat',
  'ass-pirate',
  'assbag',
  'assbandit',
  'assbanger',
  'assbite',
  'assclown',
  'asscock',
  'asscracker',
  'asses',
  'assface',
  'assfucker',
  'assfukka',
  'assgoblin',
  'asshat',
  'asshead',
  'asshole',
  'assholes',
  'asshopper',
  'assjacker',
  'asslick',
  'asslicker',
  'assmonkey',
  'assmunch',
  'assmuncher',
  'asspirate',
  'assshole',
  'asssucker',
  'asswad',
  'asswhole',
  'asswipe',
  'auto erotic',
  'autoerotic',
  'b!tch',
  'b00bs',
  'b17ch',
  'b1tch',
  'babeland',
  'baby batter',
  'baby juice',
  'ball gag',
  'ball gravy',
  'ball kicking',
  'ball licking',
  'ball sack',
  'ball sucking',
  'ballbag',
  'balls',
  'ballsack',
  'bampot',
  'bangbros',
  'bareback',
  'barely legal',
  'barenaked',
  'bastard',
  'bastardo',
  'bastinado',
  'bbw',
  'bdsm',
  'beaner',
  'beaners',
  'beastial',
  'beastiality',
  'beastility',
  'beaver cleaver',
  'beaver lips',
  'bellend',
  'bestial',
  'bestiality',
  'bi+ch',
  'biatch',
  'big black',
  'big breasts',
  'big knockers',
  'big tits',
  'bimbos',
  'birdlock',
  'bitch',
  'bitcher',
  'bitchers',
  'bitches',
  'bitchin',
  'bitching',
  'black cock',
  'blonde action',
  'blonde on blonde action',
  'bloody',
  'blow job',
  'blow your load',
  'blowjob',
  'blowjobs',
  'blue waffle',
  'blumpkin',
  'boiolas',
  'bollock',
  'bollocks',
  'bollok',
  'bollox',
  'bondage',
  'boner',
  'boob',
  'boobie',
  'boobs',
  'booobs',
  'boooobs',
  'booooobs',
  'booooooobs',
  'booty call',
  'breasts',
  'brown showers',
  'brunette action',
  'buceta',
  'bugger',
  'bukkake',
  'bulldyke',
  'bullet vibe',
  'bullshit',
  'bum',
  'bung hole',
  'bunghole',
  'bunny fucker',
  'busty',
  'butt',
  'butt-pirate',
  'buttcheeks',
  'butthole',
  'buttmunch',
  'buttplug',
  'c0ck',
  'c0cksucker',
  'camel toe',
  'camgirl',
  'camslut',
  'camwhore',
  'carpet muncher',
  'carpetmuncher',
  'cawk',
  'chinc',
  'chink',
  'choad',
  'chocolate rosebuds',
  'chode',
  'cipa',
  'circlejerk',
  'cl1t',
  'cleveland steamer',
  'clit',
  'clitface',
  'clitoris',
  'clits',
  'clover clamps',
  'clusterfuck',
  'cnut',
  'cock',
  'cock-sucker',
  'cockbite',
  'cockburger',
  'cockface',
  'cockhead',
  'cockjockey',
  'cockknoker',
  'cockmaster',
  'cockmongler',
  'cockmongruel',
  'cockmonkey',
  'cockmunch',
  'cockmuncher',
  'cocknose',
  'cocknugget',
  'cocks',
  'cockshit',
  'cocksmith',
  'cocksmoker',
  'cocksuck',
  'cocksuck ',
  'cocksucked',
  'cocksucked ',
  'cocksucker',
  'cocksucking',
  'cocksucks ',
  'cocksuka',
  'cocksukka',
  'cok',
  'cokmuncher',
  'coksucka',
  'coochie',
  'coochy',
  'coon',
  'coons',
  'cooter',
  'coprolagnia',
  'coprophilia',
  'cornhole',
  'cox',
  'crap',
  'creampie',
  'cum',
  'cumbubble',
  'cumdumpster',
  'cumguzzler',
  'cumjockey',
  'cummer',
  'cumming',
  'cums',
  'cumshot',
  'cumslut',
  'cumtart',
  'cunilingus',
  'cunillingus',
  'cunnie',
  'cunnilingus',
  'cunt',
  'cuntface',
  'cunthole',
  'cuntlick',
  'cuntlick ',
  'cuntlicker',
  'cuntlicker ',
  'cuntlicking',
  'cuntlicking ',
  'cuntrag',
  'cunts',
  'cyalis',
  'cyberfuc',
  'cyberfuck ',
  'cyberfucked ',
  'cyberfucker',
  'cyberfuckers',
  'cyberfucking ',
  'd1ck',
  'dammit',
  'damn',
  'darkie',
  'date rape',
  'daterape',
  'deep throat',
  'deepthroat',
  'dendrophilia',
  'dick',
  'dickbag',
  'dickbeater',
  'dickface',
  'dickhead',
  'dickhole',
  'dickjuice',
  'dickmilk',
  'dickmonger',
  'dickslap',
  'dicksucker',
  'dickwad',
  'dickweasel',
  'dickweed',
  'dickwod',
  'dike',
  'dildo',
  'dildos',
  'dingleberries',
  'dingleberry',
  'dink',
  'dinks',
  'dipshit',
  'dirsa',
  'dirty pillows',
  'dirty sanchez',
  'dlck',
  'dog style',
  'dog-fucker',
  'doggie style',
  'doggiestyle',
  'doggin',
  'dogging',
  'doggy style',
  'doggystyle',
  'dolcett',
  'domination',
  'dominatrix',
  'dommes',
  'donkey punch',
  'donkeyribber',
  'doochbag',
  'dookie',
  'doosh',
  'double dong',
  'double penetration',
  'douche',
  'douchebag',
  'dp action',
  'dry hump',
  'duche',
  'dumbshit',
  'dumshit',
  'dvda',
  'dyke',
  'eat my ass',
  'ecchi',
  'ejaculate',
  'ejaculated',
  'ejaculates ',
  'ejaculating ',
  'ejaculatings',
  'ejaculation',
  'ejakulate',
  'erotic',
  'erotism',
  'escort',
  'eunuch',
  'f u c k',
  'f u c k e r',
  'f4nny',
  'f_u_c_k',
  'fag',
  'fagbag',
  'fagg',
  'fagging',
  'faggit',
  'faggitt',
  'faggot',
  'faggs',
  'fagot',
  'fagots',
  'fags',
  'fagtard',
  'fanny',
  'fannyflaps',
  'fannyfucker',
  'fanyy',
  'fart',
  'farted',
  'farting',
  'farty',
  'fatass',
  'fcuk',
  'fcuker',
  'fcuking',
  'fecal',
  'feck',
  'fecker',
  'felatio',
  'felch',
  'felching',
  'fellate',
  'fellatio',
  'feltch',
  'female squirting',
  'femdom',
  'figging',
  'fingerbang',
  'fingerfuck ',
  'fingerfucked ',
  'fingerfucker ',
  'fingerfuckers',
  'fingerfucking ',
  'fingerfucks ',
  'fingering',
  'fistfuck',
  'fistfucked ',
  'fistfucker ',
  'fistfuckers ',
  'fistfucking ',
  'fistfuckings ',
  'fistfucks ',
  'fisting',
  'flamer',
  'flange',
  'fook',
  'fooker',
  'foot fetish',
  'footjob',
  'frotting',
  'fuck',
  'fuck buttons',
  'fucka',
  'fucked',
  'fucker',
  'fuckers',
  'fuckhead',
  'fuckheads',
  'fuckin',
  'fucking',
  'fuckings',
  'fuckingshitmotherfucker',
  'fuckme ',
  'fucks',
  'fucktards',
  'fuckwhit',
  'fuckwit',
  'fudge packer',
  'fudgepacker',
  'fuk',
  'fuker',
  'fukker',
  'fukkin',
  'fuks',
  'fukwhit',
  'fukwit',
  'futanari',
  'fux',
  'fux0r',
  'g-spot',
  'gang bang',
  'gangbang',
  'gangbanged',
  'gangbanged ',
  'gangbangs ',
  'gay sex',
  'gayass',
  'gaybob',
  'gaydo',
  'gaylord',
  'gaysex',
  'gaytard',
  'gaywad',
  'genitals',
  'giant cock',
  'girl on',
  'girl on top',
  'girls gone wild',
  'goatcx',
  'goatse',
  'god damn',
  'god-dam',
  'god-damned',
  'goddamn',
  'goddamned',
  'gokkun',
  'golden shower',
  'goo girl',
  'gooch',
  'goodpoop',
  'gook',
  'goregasm',
  'gringo',
  'grope',
  'group sex',
  'guido',
  'guro',
  'hand job',
  'handjob',
  'hard core',
  'hardcore',
  'hardcoresex ',
  'heeb',
  'hell',
  'hentai',
  'heshe',
  'ho',
  'hoar',
  'hoare',
  'hoe',
  'hoer',
  'homo',
  'homoerotic',
  'honkey',
  'honky',
  'hooker',
  'hore',
  'horniest',
  'horny',
  'hot carl',
  'hot chick',
  'hotsex',
  'how to kill',
  'how to murder',
  'huge fat',
  'humping',
  'incest',
  'intercourse',
  'jack off',
  'jack-off ',
  'jackass',
  'jackoff',
  'jail bait',
  'jailbait',
  'jap',
  'jelly donut',
  'jerk off',
  'jerk-off ',
  'jigaboo',
  'jiggaboo',
  'jiggerboo',
  'jism',
  'jiz',
  'jiz ',
  'jizm',
  'jizm ',
  'jizz',
  'juggs',
  'kawk',
  'kike',
  'kinbaku',
  'kinkster',
  'kinky',
  'kiunt',
  'knob',
  'knobbing',
  'knobead',
  'knobed',
  'knobend',
  'knobhead',
  'knobjocky',
  'knobjokey',
  'kock',
  'kondum',
  'kondums',
  'kooch',
  'kootch',
  'kum',
  'kumer',
  'kummer',
  'kumming',
  'kums',
  'kunilingus',
  'kunt',
  'kyke',
  'l3i+ch',
  'l3itch',
  'labia',
  'leather restraint',
  'leather straight jacket',
  'lemon party',
  'lesbo',
  'lezzie',
  'lmfao',
  'lolita',
  'lovemaking',
  'lust',
  'lusting',
  'm0f0',
  'm0fo',
  'm45terbate',
  'ma5terb8',
  'ma5terbate',
  'make me come',
  'male squirting',
  'masochist',
  'master-bate',
  'masterb8',
  'masterbat*',
  'masterbat3',
  'masterbate',
  'masterbation',
  'masterbations',
  'masturbate',
  'menage a trois',
  'milf',
  'minge',
  'missionary position',
  'mo-fo',
  'mof0',
  'mofo',
  'mothafuck',
  'mothafucka',
  'mothafuckas',
  'mothafuckaz',
  'mothafucked ',
  'mothafucker',
  'mothafuckers',
  'mothafuckin',
  'mothafucking ',
  'mothafuckings',
  'mothafucks',
  'mother fucker',
  'motherfuck',
  'motherfucked',
  'motherfucker',
  'motherfuckers',
  'motherfuckin',
  'motherfucking',
  'motherfuckings',
  'motherfuckka',
  'motherfucks',
  'mound of venus',
  'mr hands',
  'muff',
  'muff diver',
  'muffdiver',
  'muffdiving',
  'mutha',
  'muthafecker',
  'muthafuckker',
  'muther',
  'mutherfucker',
  'n1gga',
  'n1gger',
  'nambla',
  'nawashi',
  'nazi',
  'negro',
  'neonazi',
  'nig nog',
  'nigg3r',
  'nigg4h',
  'nigga',
  'niggah',
  'niggas',
  'niggaz',
  'nigger',
  'niggers ',
  'niglet',
  'nimphomania',
  'nipple',
  'nipples',
  'nob',
  'nob jokey',
  'nobhead',
  'nobjocky',
  'nobjokey',
  'nsfw images',
  'nude',
  'nudity',
  'numbnuts',
  'nutsack',
  'nympho',
  'nymphomania',
  'octopussy',
  'omorashi',
  'one cup two girls',
  'one guy one jar',
  'orgasim',
  'orgasim ',
  'orgasims ',
  'orgasm',
  'orgasms ',
  'orgy',
  'p0rn',
  'paedophile',
  'paki',
  'panooch',
  'panties',
  'panty',
  'pawn',
  'pecker',
  'peckerhead',
  'pedobear',
  'pedophile',
  'pegging',
  'penis',
  'penisfucker',
  'phone sex',
  'phonesex',
  'phuck',
  'phuk',
  'phuked',
  'phuking',
  'phukked',
  'phukking',
  'phuks',
  'phuq',
  'piece of shit',
  'pigfucker',
  'pimpis',
  'pis',
  'pises',
  'pisin',
  'pising',
  'pisof',
  'piss',
  'piss pig',
  'pissed',
  'pisser',
  'pissers',
  'pisses ',
  'pissflap',
  'pissflaps',
  'pissin',
  'pissin ',
  'pissing',
  'pissoff',
  'pissoff ',
  'pisspig',
  'playboy',
  'pleasure chest',
  'pole smoker',
  'polesmoker',
  'pollock',
  'ponyplay',
  'poo',
  'poof',
  'poon',
  'poonani',
  'poonany',
  'poontang',
  'poop',
  'poop chute',
  'poopchute',
  'porn',
  'porno',
  'pornography',
  'pornos',
  'prick',
  'pricks ',
  'prince albert piercing',
  'pron',
  'pthc',
  'pube',
  'pubes',
  'punanny',
  'punany',
  'punta',
  'pusies',
  'pusse',
  'pussi',
  'pussies',
  'pussy',
  'pussylicking',
  'pussys ',
  'pusy',
  'puto',
  'queaf',
  'queef',
  'queerbait',
  'queerhole',
  'quim',
  'raghead',
  'raging boner',
  'rape',
  'raping',
  'rapist',
  'rectum',
  'renob',
  'retard',
  'reverse cowgirl',
  'rimjaw',
  'rimjob',
  'rimming',
  'rosy palm',
  'rosy palm and her 5 sisters',
  'ruski',
  'rusty trombone',
  's hit',
  's&m',
  's.o.b.',
  's_h_i_t',
  'sadism',
  'sadist',
  'santorum',
  'scat',
  'schlong',
  'scissoring',
  'screwing',
  'scroat',
  'scrote',
  'scrotum',
  'semen',
  'sex',
  'sexo',
  'sexy',
  'sh!+',
  'sh!t',
  'sh1t',
  'shag',
  'shagger',
  'shaggin',
  'shagging',
  'shaved beaver',
  'shaved pussy',
  'shemale',
  'shi+',
  'shibari',
  'shit',
  'shit-ass',
  'shit-bag',
  'shit-bagger',
  'shit-brain',
  'shit-breath',
  'shit-cunt',
  'shit-dick',
  'shit-eating',
  'shit-face',
  'shit-faced',
  'shit-fit',
  'shit-head',
  'shit-heel',
  'shit-hole',
  'shit-house',
  'shit-load',
  'shit-pot',
  'shit-spitter',
  'shit-stain',
  'shitass',
  'shitbag',
  'shitbagger',
  'shitblimp',
  'shitbrain',
  'shitbreath',
  'shitcunt',
  'shitdick',
  'shite',
  'shiteating',
  'shited',
  'shitey',
  'shitface',
  'shitfaced',
  'shitfit',
  'shitfuck',
  'shitfull',
  'shithead',
  'shitheel',
  'shithole',
  'shithouse',
  'shiting',
  'shitings',
  'shitload',
  'shitpot',
  'shits',
  'shitspitter',
  'shitstain',
  'shitted',
  'shitter',
  'shitters ',
  'shittiest',
  'shitting',
  'shittings',
  'shitty',
  'shitty ',
  'shity',
  'shiz',
  'shiznit',
  'shota',
  'shrimping',
  'skank',
  'skeet',
  'slanteye',
  'slut',
  'slutbag',
  'sluts',
  'smeg',
  'smegma',
  'smut',
  'snatch',
  'snowballing',
  'sodomize',
  'sodomy',
  'son-of-a-bitch',
  'spac',
  'spic',
  'spick',
  'splooge',
  'splooge moose',
  'spooge',
  'spread legs',
  'spunk',
  'strap on',
  'strapon',
  'strappado',
  'strip club',
  'style doggy',
  'suck',
  'sucks',
  'suicide girls',
  'sultry women',
  'swastika',
  'swinger',
  't1tt1e5',
  't1tties',
  'tainted love',
  'tard',
  'taste my',
  'tea bagging',
  'teets',
  'teez',
  'testical',
  'testicle',
  'threesome',
  'throating',
  'thundercunt',
  'tied up',
  'tight white',
  'tit',
  'titfuck',
  'tits',
  'titt',
  'tittie5',
  'tittiefucker',
  'titties',
  'titty',
  'tittyfuck',
  'tittywank',
  'titwank',
  'tongue in a',
  'topless',
  'tosser',
  'towelhead',
  'tranny',
  'tribadism',
  'tub girl',
  'tubgirl',
  'turd',
  'tushy',
  'tw4t',
  'twat',
  'twathead',
  'twatlips',
  'twatty',
  'twink',
  'twinkie',
  'two girls one cup',
  'twunt',
  'twunter',
  'undressing',
  'upskirt',
  'urethra play',
  'urophilia',
  'v14gra',
  'v1gra',
  'va-j-j',
  'vag',
  'vagina',
  'venus mound',
  'viagra',
  'vibrator',
  'violet wand',
  'vjayjay',
  'vorarephilia',
  'voyeur',
  'vulva',
  'w00se',
  'wang',
  'wank',
  'wanker',
  'wanky',
  'wet dream',
  'wetback',
  'white power',
  'whoar',
  'whore',
  'willies',
  'willy',
  'wrapping men',
  'wrinkled starfish',
  'xrated',
  'xx',
  'xxx',
  'yaoi',
  'yellow showers',
  'yiffy',
  'zoophilia',
];

export const promptTemplate = [
  {
    trait_type: 'Object',
    values: [
      'A Humanoid android',
      'A Amazonian shaman',
      'A Robot',
      'A Ninja',
      'A Fantasy Warrior',
      'A Rugged old miner',
      'A Cyborg',
      'A Knight',
      'A Yeti',
      'A Futuristic female fighter',
      'A Viking',
      'Kratos',
      'A Owl',
      'A Dragon',
      'A Wizard',
      'A King',
      'A Queen',
      'A Old warrior chief',
      'A Cotton mill girl',
      'Native villagers',
      'A Native warrior',
      'A Native chief',
      'A Forest wanderer',
      'A Mad scientist',
      'A Fantasy character',
      'A Futuristic character',
      'A Majestic blobfish knight',
      'A Mystic Old Male Warrior',
      'A Beautiful young woman',
      'A Fantasy butterfly',
      'A Snow owl',
      'Marshmallows',
      'Tree of life',
      'Glass flowers',
      'A Cute ginger cat',
      'A Cyberpunk girl in jacket',
      'A Giant armored mammoth',
      'A melting apple',
      'A Fuchikoma-android',
      'A Clowngirl',
      'A mini golden doodle',
      'A Crescent moon',
      'A melting strawberry',
      'A Astronaut',
      'Robot koi fish',
      'A Beautiful cyborg princess',
      'A fisherman',
      'A Black cat',
      'A Furry and cute bird',
      'A Lion cub',
      'A cute little robot',
      'A frog',
      'A Cute winter dragon baby',
      'A cartoon boy',
    ],
  },
  {
    trait_type: 'Event',
    values: [
      'Glowing eyes',
      'Rainbow dreadlocks',
      'Grey beard',
      'Screaming',
      'Long celestial hair in the wind',
      'Tears of ice',
      'Wires to head',
      'Hoodie up',
      'Joker face',
      'Tribal make up',
      'Looking away',
      'Looking at you',
      'Looking at the viewer',
      'Looking at the camera',
      'Looking at the sky',
      'Looking at the ground',
      'Looking at the horizon',
      'Piercing green eyes',
      'Piercing blue eyes',
      'Side profile view',
      'Front profile view',
      'Headphones on',
      'Facing camera',
      'Finely detailed armor',
      'Wearing a mask',
      'Wearing a helmet',
      'Wearing a hat',
      'White porcelain skin',
      'Neon glowing eyes',
      'Looking straight at you',
      'Tech Helmet',
      'Neon Goggles',
      'Massive aztec headdress',
    ],
  },
  {
    trait_type: 'Scene',
    values: [
      'Futuristic city',
      'Abandoned research station',
      'Asian alleyway',
      'Deep dark forest',
      'Rocket launch site',
      'Old factory',
      'In space',
      'On water',
      'Sinking ship',
      'Crashed spaceship',
      'Post-apocalyptic landscape',
      'Post-apocalyptic city',
      'Old river',
      'A sunny beach',
      'Background mountains',
      'Background city',
      'Background forest',
      'Background desert',
      'Temple in ruines',
      'Temple in the jungle',
      'Temple in the mountains',
      'City made out of glass',
      'Old cabin',
      'Old house',
      'Old castle',
      'Old church',
      'Old cathedral',
      'Old mansion',
      'Old building',
      'Old warehouse',
      'Old barn',
      'Old mill',
      'Old bridge',
      'Old tunnel',
      'Old train station',
      'Old train',
      'Old car',
      'Lone skyscraper',
      'Lone building',
      'Lone house',
      'Lone cabin',
      'Tall factory',
      'Tall building',
      'Tall skyscraper',
      'Tall tower',
      'Rough ocean storm',
      'Rough ocean',
      'Postapocalyptic city',
      'Big labyrinth',
      'Big maze',
      'Big cave',
      'Narrow steep staircase',
      'Floating buildings',
      'Nostalgic diner',
      'Nostalgic gas station',
      'Nostalgic motel',
      'Nostalgic drive-in',
      'Asteroid field',
      'Asteroid belt',
      'Asteroid mining station',
      'Candy world',
      'An expansive view of the universe',
      'Many ancient buildings',
      'Many ancient temples',
    ],
  },
  {
    trait_type: 'Environment',
    values: [
      'Stunning environment',
      'Wide-angle',
      'Massive scale,',
      'Street level view',
      'Landscape',
      'Panoramic',
      'Lush vegetation',
      'Idyllic',
      'Overhead shot',
      'Rain',
      'Swirling smoke',
      'Cold',
      'Beautiful',
      'Colorful',
      'Powerful',
      'Intricate',
      'Detailed',
      'Massive',
      'Shiny',
      'Smooth',
      'Divine',
      'Celestial',
      'Futuristic',
      'Retro',
      'Vintage',
      'Modern',
      'Ancient',
      'Medieval',
      'Industrial',
      'Urban',
      'Rural',
      'Natural',
      'Artificial',
      'Cinematic atmosphere',
      'Mysterious',
      'Magical',
      'Dreamy',
      'Mythology',
      'Mist',
      'Fog',
      'Wisdom',
      'Urban',
    ],
  },
  {
    trait_type: 'Lighting',
    values: [
      'Cinematic lighting',
      'Dynamic lighting',
      'Dramatic lighting',
      'Rim lighting',
      'Soft lighting',
      'Fantastic backlight',
      'Studio light',
      'God rays',
      'Hard shadows',
      'Diffused lighting',
      'Volumetric lighting',
      'Specular lighting',
      'Luminescence',
      'Translucency',
      'Subsurface scattering',
      'Global illumination',
      'Indirect light',
      'Hard rim lighting',
      'Soft rim lighting',
      'Soft shadows',
      'Hard shadows',
      'Soft light',
      'Hard light',
      'Diffused light',
      'Professional studio lighting',
      'Natural light',
      'Artificial light',
      'Rembrandt lighting',
    ],
  },
  {
    trait_type: 'Colors',
    values: [
      'Vibrant',
      'Muted colors',
      'Vivid color',
      'Post-processing',
      'Colorgrading',
      'Tone mapping',
      'Lush',
      'Low contrast',
      'Vintage',
      'Aesthetic',
      'Psychedelic',
      'Monochrome',
      'Black & white',
      'Colors',
      'HDR',
      'Pastel',
      'Neon',
      'Warm',
      'Cold',
      'Contrast',
      'High contrast',
      'Low contrast',
      'Colorful',
      'Colorless',
      'Color grading',
      'Morandi colors',
    ],
  },
  {
    trait_type: 'Style',
    values: [
      'Surrealism',
      'Realism',
      'Cubism',
      'Contemporary',
      'Fantasy',
      'Abstract',
      'Photorealistic',
      'Acrylic',
      'Pastel',
      'Digital art',
      'Cyberpunk',
      'Spray Paint',
      'Hyperrealistic',
      'Surreal',
      'Realistic CGI',
      'Bionic futurism',
      'Atompunk',
      'Steampunk',
      'Concept art',
      'Futurism',
      'Ultra realistic',
      'Film photography',
      'Analog photo',
      'Macro photography',
      'Afga Vista 400',
      'Halftone',
      'Character design',
      'Precise lineart',
      'Perfectionism',
      'Pixel art',
      'National geographic',
      'Sculpture',
      'Movie concept art',
      'Game concept art',
      'Comic book art',
      'Comic book style',
      'Intricate filigree metal design',
      'Art nouveau',
      'Concept Character',
      'Telescope photography',
    ],
  },
  {
    trait_type: 'ExtraOne',
    values: [
      'A city in the year 2100',
      'A grand city in the year 2200',
      'The eye of the storm',
      'Futuristic New York City skyline',
      'Tree house in the forest',
      'A trail through the unknown',
      'A trail through the forest',
      'A trail through the mountains',
      'The surface of Europa',
      'The surface of Mars',
      'Galaxy background',
      'Ancient ruins',
      'Ancient city',
      'Ancient temple',
      'Ancient forest',
      'Ancient mountains',
      'Radiant light rays',
      'Ektachrome',
      'Glowing',
      'Shimmering light',
      'Halo',
      '80mm lense',
      '50mm lense',
      '35mm lense',
      '24mm lense',
      '12mm lense',
      'Ray tracing',
      'Atmospheric',
      'Anthropomorphic',
      'Very detailed',
      'Sharp focus',
      'Volumetric',
      'Iridescent',
    ],
  },
  {
    trait_type: 'ExtraTwo',
    values: [
      'Iridescent',
      'Backlighting',
      'Grimy',
      'Trippy',
      'Dirty',
      'Glowing',
      'Moody',
      'Golden',
      'Soft',
      'Fascinating',
      'Keyvisual',
      'Elegant',
      'Surreal',
      'Lightning',
      'Dark',
      'Bright',
      'Symmetrical',
      '3D art',
      '3D render',
      'Elegant',
      'Centered',
      'Dramatic angle',
      'Excellent composition',
      'Aesthetic',
      'Minimalistic',
      'Simplistic',
      'Complex',
      '3D model',
      '3D animation',
      'Modern',
      'Impressionism',
      'Minimal',
      'Watercolor',
      'Pop Art',
      'Oil painting',
      'Anime',
      '3D illustration',
      '3D design',
      '3D sculpture',
    ],
  },
  {
    trait_type: 'ExtraThree',
    values: [
      'A beautiful painting',
      'A sketch',
      'A painting',
      'A rendering',
      'A picture',
      'A photo',
      'A cool painting',
      'A close - up painting',
      'A rendition',
      'A nice painting',
      'Award winning design',
      'Award winning illustration',
      'Award winning painting',
      'Nikon',
      'Canon',
      'Sony',
      'Fujifilm',
      'Blender',
      'Leica',
      'Hasselblad',
      'Pentax',
      'Photoshopped',
      'Photoshop',
      'High resolution',
      'High res',
      'High quality',
      'High quality render',
      'High quality photo',
      'High quality art',
      'Overdetailed',
      'Very complex',
      'Very detailed',
      'Very intricate',
      'BioShock',
      'Insanely detailed',
      'Very inspirational',
      'Wimmelbilder',
      'Wimmelbild',
      'A small painting',
      'A weird painting',
      'A image',
      'A large painting',
      'Multi dimensional paper quilling',
      'A polaroid',
      'A wallpaper',
      'Elevation perspective',
      'A poster',
      'A 50mm portrait photography',
      'A portrait photo',
      'A studio portrait',
      'A detailed illustration',
      'A mural painting',
      'Tunnel composition',
      'Aurora borealis',
      'Aurora',
    ],
  },
  {
    trait_type: 'ExtraFour',
    values: [
      '4k',
      '8k',
      'Trending on artstation',
      'Unreal engine',
      'Octane render',
      'Intricate filigree metal design',
      'Symmetrical',
      'Multiverse',
      'Time loop',
      'Maximum texture',
      'Dynamic',
      'Bokeh',
      'Vray',
      'Houdini render',
      'Quixel megascans',
      'Arnold render',
      'UHD',
      'CGI',
      'Lush detail',
      'Volumetric fog',
      'Lumen reflections',
      'Cgsociety',
      'Dslr',
      'Cinema4d',
      'Pixar',
      'Studio quality',
      'Film grain',
      'Volumetric fog',
      'Depth of field',
      'DOF',
      'Silhouette',
      'Motion blur',
      'Ultra-wide angle',
      'Evocative',
      'Award winning',
      'Trending on dribbble',
      'Insanely detailed',
      'Lush detail',
      'Filigree',
      'Crystalline',
      'Masterpiece',
      'Breathtaking',
      'Mind-blowing',
      'Mind-bending',
      'Award winning photo',
      'Award winning art',
      'Cinematic epic',
      'Lord of the rings',
      'The hobbit',
      'Game of thrones',
      'DeviantArt',
      'Flickr',
      '16K resolution',
      'Astonishing',
      'Intricate detail',
    ],
  },
  {
    trait_type: 'Artist',
    values: [
      'Rumiko Takahashi',
      'Naoko Takeuchi',
      'Sailor Moon',
      'Shotaro Ishinomori',
      'Yoshiyuki Tomino',
      'Mucha',
      'Kentaro Miura',
      'Inio Asano',
      'Ken Sugimori',
      'Hirohiko Araki',
      'Toei Animations',
      'Hasegawa Tōhaku',
      'Hariton Pushwagner',
      'Posuka Demizu',
      'Eiichiro Oda',
      'Phil Noto',
      'Hiromu Arakawa',
      'Kobayashi Kiyochika',
      'Gai Qi',
      'Michael Cheval',
      'Adam Hughes',
      'Armin Hansen',
      'Karl Blossfeldt',
      'Henri Cartier-Bresson',
      'Bruce Gilden',
      'Hugh Ferriss',
      'Paul Strand',
      'Ando Fuchs',
      'Lee Jeffries & Damien Hurst',
      'Lee Jeffries',
      'Jovana Rikalo',
      'James Jean',
      'Steve McCurry',
      'Zeng Fanzhi',
      'Manny Librodo',
      'Joao Ruas',
      'Lee Jeffries & Takashi Murakami',
      'Lee Jeffries & Invader',
      'Lee Jeffries & Barry McGee',
      'Lee Jeffries & Kaws',
      'Lee Jeffries & Futura',
      'Alphonse Mucha',
      'Daniela Uhlig',
      'Liam Wong',
      'Ismail Inceoglu',
      'Alfonse Mucha',
      'Christopher Balaskas',
      'Andy Fairhurst',
      'Joe Madureira',
      'Taiyō Matsumoto',
      'Charlie Bowater',
      'Jessica Rossier',
      'Krenz Cushart',
      'Akihiko Yoshida',
      'Ruan Jia',
      'Alejandro Burdisio',
      'Ross Tran',
      'Andreas Rocha',
      'Craig Davison',
      'Roy Lichtenstein',
      'Tatsuro Kiuchi',
      'Hiroshi Nagai',
      'Andy Kehoe',
      'Conrad Roset',
      'Victo Ngai',
      'Jeremiah Ketner',
      'Osamu Tezuka',
      'Carl Barks',
      'NHK Animation',
      'theCHAMBA',
      'Pixar Concept Artists',
      'Todd McFarlane',
      'Hanna-Barbera',
      'Ethan Van Sciver',
      'Bryan Hitch',
      'Josan Gonzalez',
      'Pixar',
      'Kaws',
      'Mike Mignola',
      'Hisui Sugiura',
      'Ian Miller',
      'Byeon Sang-byeok',
      'Guo Pei',
      'Hiroyuki Tajima',
      'Inoue Naohisa',
      'Hiromitsu Takahashi',
      'Cheng Zhengkui',
      'Gao Xiang',
      'Byron Galvez',
      'Qian Xuan',
      'Aguri Uchida',
      'Keith Haring',
      'Alma Thomas',
      'Eduardo Kobra',
      'Roz Chast',
      'Yayoi Kusama',
      'Takashi Murakami',
      'Brandon Mably',
      'Rebecca Louise Law',
      'OSGEMEOS',
      'Jaume Plensa',
      'Hanabusa Itchō II',
      'Kunisada',
      'Hasui Kawase',
      'Ohara Koson',
      'Leonardo Da Vinci',
      'J.M.W. Turner',
      'Walter Crane',
      'Chagall',
      'Harumi Hironaka',
      'Hans Hofmann',
      'Andy Warhol',
      'Barbara Takenaga',
      'Banksy',
      'Tom Whalen',
      'Ernst Wilhelm Nay',
      'Annie Soudain',
      'Albert Gleizes',
      'Yue Minjun',
      'Art Spiegelman',
      'Mordecai Ardon',
      'Etel Adnan',
      'Saul Bass',
      'Brian Stelfreeze',
      'Honoré Daumier',
      'Edvard Munch',
      'Willem de Kooning',
      'Leonora Carrington',
      'Hieronymus Bosch',
      'Pierre Bonnard',
      'Andre Derain',
      'Beauford Delaney',
      'David Alfaro Siqueiros',
      'Hope Gangloff',
      'William Steig',
      'Octavio Ocampo',
      'Pablo Picasso',
      'Damien Hirst',
      'Diane Dillon',
      'Hans Erni',
      'Hsiao-Ron Cheng',
      'Gerda Wegener',
      'Peter Bagge',
      'Emiliano Ponzi',
      'Man Ray',
      'Lois van Baarle',
      'Arthur Garfield Dove',
      'Otto Dix',
      'Salvador Dali',
      'Steve Lieber',
      'Balthus',
      'Jeff Kinney',
      'Romero Britto',
      'Joan Miró',
      'Jean-Michel Basquiat',
      'Paul Klee',
      'Marc Chagall',
      'Karl Schmidt-Rottluff',
      'Howard Hodgkin',
      'Jean Metzinger',
      'Rufino Tamayo',
      'Vincent Van Gogh',
      'Wassily Kandinsky',
      'Mary Blair',
      'Charles Rennie Mackintosh',
      'Edmund Dulac',
      'Chris Ofili',
      'Dale Chihuly',
      'Elizabeth Gadd',
      'Joel Sternfeld',
      'Alex Timmermans',
      'Anton Corbijn',
      'David Octavius Hill',
      'teamLab',
      'Andre De Dienes',
      'Bordalo II',
      'Brassaï',
      'Hiroshi Sugimoto',
      'Alasdair McLellan',
      'Bjarke Ingels',
      'Tim Doyle',
      'Quentin Tarantino',
      'Dora Maar',
      'Cornelis Bisschop',
      'Anton Semenov',
      'Sandy Skoglund',
      'Os Gemeos',
      'Nathan Coley',
      'Jasmine Becket-Griffith',
      'Edi Rama',
      'Derek Jarman',
      'I Ketut Soki',
      'Odd Nerdrum',
      'Ed Roth',
      'Alvaro Siza',
      'Wim Crouwel',
      'Kuang Hong',
      'Tokujin Yoshioka',
      'Eizō Katō',
      'Grethe Jürgens',
      'Angela Barrett',
      'Art Green',
      'Amy Judd',
      'James Jean',
      'Alan Parry',
      'Elizabeth Jane Lloyd',
      'Sean Yoro',
      'Ai Weiwei',
      'Ikuo Hirayama',
      'Benjamin Block',
      'Gonzalo Endara Crow',
      'Gerald Kelley',
      'Faith 47',
      'Horatio McCulloch',
      'Wes Anderson',
      'Moebius',
      'Anne Savage',
      'Clara Weaver Parrish',
      'Dorothy Coke',
      'Gong Kai',
      'Lady Pink',
      'Adrian Smith',
      'Abraham Mintchine',
      'Tim Burton',
      'Du Jin',
      'Annabel Eyres',
      'Zack Snyder',
      'Alexander McQueen',
      'Cam Sykes',
      'George Lucas',
      'Eglon van der Neer',
      'Christian August Lorentzen',
      'Caspar Netscher',
      'Walt Disney',
      'Geoffrey Dyer',
      'Gu Zhengyi',
      'Aleksander Kotsis',
      'Dalí',
      'David la chapelle',
      'Dante Gabriel Rossetti',
      'Dennis Hopper',
      'Dennis Stock',
      'Haukur Halldórsson',
      'Ollie Hoff',
      'RHADS',
      'Alexander Carse',
      'Doc Hammer',
      'Yuumei',
      'Gang Se-hwang',
      'Bjørn Wiinblad',
      'Alex Horley-Orlandelli',
      'Dr. Atl',
      'Bertil Nilsson',
      'Giuseppe Camuncoli',
      'Elsie Vera Cole',
      'Fan Kuan',
      'Dean Roger',
      'Ei-Q',
      'Allen Tupper True',
      'Jef Wu',
      'David Bailly',
      'Hans Arnold',
      'David Finch',
      'RETNA (Marquis Lewis)',
      'Bapu',
      'Dr. Seuss',
      'Henning Jakob Henrik Lund',
      'Garry Winogrand',
      'Alvin Langdon Coburn',
      'Bert Hardy',
      'Anne Brigman',
      'Hervé Guibert',
      'John Stezaker',
      'Alfred Eisenstaedt',
      'Albert Servaes',
      'Gaugin',
      'David Teniers the Younger',
      'Claude Lorrain',
      'Winslow Homer',
      'Horace Vernet',
      'Lucas Cranach the Elder',
      'Alberto Vargas',
      'Alena Aenami',
      'Maurice Sendak',
      'Butcher Billy',
      'W. Heath Robinson',
      'Carl Larsson',
      'Henri De Toulouse Lautrec',
      'Ed Mell',
      'Rebecca Guay',
      'Jeffrey Catherine Jones',
      'Laurie Greasley',
      'Gustaf Tenggren',
      'Terada Katsuya',
      'Brian K. Vaughan',
      'Klaus Janson',
      'Chip Zdarsky',
      'Goro Fujita',
      'Will Eisner',
      'Ernie Barnes',
      'Ub Iwerks',
      'Tex Avery',
      'Phil Jimenez',
      'Mike Deodato',
      'Casey Weldon',
      'Glen Keane',
      'Matt Fraction',
      'Dan Mumford',
      'Fra Angelico',
      'Andrei Rublev',
      'George barbier',
      'W.W. Denslow',
      'Giotto Di Bondone',
      'John Hoyland',
      'Janek Sedlar',
      'Agnolo Gaddi',
      'Giovanni Battista Piranesi',
      'Johannes Vermeer',
      'Mikhail Nesterov',
      'Chris Van Allsburg',
      'Giotto',
      'Simone Martini',
      'Ivan Bilibin',
      'Giovanni da Udina',
      'Henri-Edmond Cross',
      'Bill Jacklin',
      'Eugène Grasset',
      'Arthur Rackham',
      'Elsa Beskow',
      'Andrea del Verrocchio',
      'Delphin Enjolras',
      'Margaret Macdonald Mackintosh',
      'Cimabue',
      'Virgil Finlay',
      'Bartolomé Esteban Murillo',
      'Godfried Schalcken',
      'Beksinski',
      'L. Birge Harrison',
      'Andreas Achenbach',
      'Hayv Kahraman',
      'El Greco',
      'Antonio Mancini',
      'Hans Baldung',
      'Frans Hals',
      'Joao Ruas',
      'E. T. A. Hoffmann',
      'Henricus Hondius II',
      'Raymond Leech',
      'Titian',
      'Anna Füssli',
      'György Rózsahegyi',
      'Luis Royo',
      'Émile Gallé',
      'Eliseu Visconti',
      'Esao Andrews',
      'Syd Mead',
      'Dionisius',
      'Edwin Georgi',
      'Jenny Saville',
      'Carlo Carlone',
      'Chen Rong',
      'Ettore Tito',
      'Fritz Baumann',
      'Michal Lisowski',
      'Art Fitzpatrick',
      'Apelles',
      'Cricorps Grégoire',
      'Raymond Swanland',
      'Ralph Horsley',
      'Terry Redlin',
      'Ken Kelly',
      'David Eugene Henry',
      'Art of Brom',
      'Artgerm',
    ],
  },
];

export const promptModels = {
  sd15: {
    label: 'Portraiture.ai',
    value: 'sd-15',
    info: 'Portraitures custom trained model',
  },
  dalle: {
    label: 'Dall-e 2',
    value: 'dalle',
    info: 'OpenAIs Dall-e 2 model',
  },
  openjourney: {
    label: 'Openjourney',
    value: 'openjourney',
    info: 'Generates Midjourney v4 like images',
  },
  anything: {
    label: 'Anime',
    value: 'anything',
    info: 'Generates Anime style images',
  },
  erlich: {
    label: 'Logo Maker',
    value: 'erlich',
    info: 'Generates logos from text',
  },
};

export const imageProccessors = [
  {label: 'Depth Map', value: 'depth'},
  {label: 'Outlines', value: 'canny'},
  {label: '3D Map', value: 'normal'},
  {label: 'Photostat', value: 'scribble'},
];
