import React, { useState, useEffect, useRef } from "react";
import type { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import PortraitureIcon from "../../../components/ui/PortraitureIcon";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import "./privacy.css";
import "../../../App.css";

const listData = [
  {
    title: "Introduction",
    text: 'Portraiture.ai ("we," "us," or "our") is committed to protecting your privacy. This Privacy Policy describes how we collect, use, and disclose your personal information when you use our website, mobile application, and related services (collectively, the "Services"). By using the Services, you agree to the collection and use of information in accordance with this Privacy Policy. If you do not agree with this Privacy Policy, please do not use the Services.',
    list: [],
  },
  {
    title: "Information We Collect",
    text: "We collect several types of information from and about users of our Services, including:",
    list: [
      "Personal information, such as your name, email address, and social media login information when you create an account or log in through a social media platform.",
      "Technical information, such as your IP address, device type, operating system, and browser type, when you use our Services.",
      "Usage information, such as the content you upload and generate through the Services, as well as information about how you interact with the Services.",
    ],
  },
  {
    title: "How We Use Your Information",
    text: "We use the information we collect to:",
    list: [
      "Provide, maintain, and improve the Services.",
      "Personalize and enhance your experience using the Services.",
      "Communicate with you, including responding to your inquiries and sending updates or promotional materials.",
      "Monitor and analyze usage and trends to improve the Services and develop new features.",
      "Detect, investigate, and prevent fraudulent transactions, abuse, and other illegal activities.",
      "Enforce our Terms of Service and comply with applicable laws and regulations.",
    ],
  },
  {
    title: "How We Share Your Information",
    text: "We may share your information with:",
    list: [
      "Third-party service providers who perform services on our behalf, such as hosting, analytics, and customer support.",
      "Social media platforms when you log in or share content through their services.",
      "Law enforcement agencies or other third parties when required by law or to protect the rights, property, or safety of us, our users, or others.",
      "Other users of the Services when you choose to share your content publicly.",
      "A buyer or successor in the event of a merger, acquisition, or similar transaction.",
    ],
  },
  {
    title: "Data Security",
    text: "We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, use, disclosure, alteration, or destruction. However, no method of transmission or storage is completely secure, and we cannot guarantee the absolute security of your information.",
    list: [],
  },
  {
    title: "Data Retention",
    text: "We retain your personal information for as long as necessary to fulfill the purposes for which it was collected or as required by applicable laws and regulations. You may request that we delete your personal information by contacting us at info@portraiture.ai.",
    list: [],
  },
  {
    title: "Childrens Privacy",
    text: "Our Services are not intended for individuals under the age of 13. We do not knowingly collect personal information from children under 13, and if we become aware that we have inadvertently collected such information, we will take steps to delete it.",
    list: [],
  },
  {
    title: "Third-Party Links",
    text: "The Services may contain links to third-party websites, services, or resources. We are not responsible for the privacy practices of such third parties, and your use of their websites, services, or resources is subject to their privacy policies.",
    list: [],
  },
  {
    title: "Changes to This Privacy Policy",
    text: "We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws and regulations. We will notify you of any material changes by posting the updated Privacy Policy on our website or through other means of communication. Your continued use of the Services after the effective date of the updated Privacy Policy constitutes your acceptance of the changes.",
    list: [],
  },
  {
    title: "Contact Us",
    text: "If you have any questions or concerns about this Privacy Policy, please contact us at info@portraiture.ai.",
    list: [],
  },
];

function Privacy() {
  return (
    <div className="TemplateOuter">
      <div className="Faq">
        {listData.map((item, index) => (
          <div className="FaqItemOuter" key={index}>
            <div className="FaqItem">
              <p className="FaqItemTitle">{item.title}</p>
              <p className="FaqItemSubtitle">{item.text}</p>
              {item.list.length > 0
                ? item.list.map((listItem, index2) => (
                    <p className="TermsListText" key={index2}>
                      - {listItem}
                    </p>
                  ))
                : null}
            </div>
            <div className="FaqDivider"></div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Privacy;
