import React, { useState, useEffect } from "react";
import { firebaseAuth, firestore } from "../../components/firebase/firebase";
import Button from "@mui/material/Button";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import FilterListIcon from "@mui/icons-material/FilterList";
import SourceIcon from "@mui/icons-material/Source";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import TextSnippetOutlinedIcon from "@mui/icons-material/TextSnippetOutlined";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { saveAs } from "file-saver";
import { TransitionProps } from "@mui/material/transitions";
import { useNavigate } from "react-router-dom";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  deleteDoc,
  where,
  query,
  orderBy,
  limit,
  startAfter,
  endAt,
  startAt,
  getDoc,
  onSnapshot,
  OrderByDirection,
} from "firebase/firestore";
import {
  useAppSelector,
  useAppDispatch,
} from "../../components/redux/reduxHooks";
import { useSpring, animated } from "@react-spring/web";
import "./gallery.css";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { Waypoint } from "react-waypoint";
import { set } from "firebase/database";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Gallery() {
  const userState = useAppSelector((state: any) => state.redux.user);
  const [loading, setLoading] = useState(true);
  const [activeImages, setActiveImages] = useState(false);
  const [isAsc, setIsAsc] = useState(false);
  const [isPrompt, setIsPrompt] = useState(false);
  const [allImages, setAllImages] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [markedImages, setMarkedImages] = useState<string[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [lastVisible, setLastVisible] = useState<number | null>(null);
  const [lastVisibleLength, setLastVisibleLength] = useState(0);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [searchValueSet, setSearchValueSet] = useState("");
  const [listOrder, setListOrder] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (lastVisible === null) {
      return;
    }
    if (userState === null) {
      return;
    }
    if (isDeleting === true) {
      return;
    }
    let collectionType = "generatedImages";
    if (activeImages === true) {
      collectionType = "userImages";
    }
    let sortOrder: OrderByDirection | undefined = "desc";
    if (isAsc === true) {
      sortOrder = "asc";
    }

    let sortType = "timestamp";
    if (isPrompt === true) {
      sortType = "prompt";
    }
    setIsFetchingMore(true);
    const allActiveDocsRef = collection(
      firestore,
      "generated",
      userState.uid,
      "generatedImages"
    );
    const allActiveDocsQuery = query(
      allActiveDocsRef,
      orderBy(sortType, sortOrder),
      limit(lastVisible)
    );
    const allActiveDocsSnap = onSnapshot(
      allActiveDocsQuery,
      (querySnapshot) => {
        const images: any[] = [];

        querySnapshot.forEach((documentSnapshot) => {
          console.log(documentSnapshot.data());
          images.push({
            ...documentSnapshot.data(),
            key: documentSnapshot.id,
            marked: false,
          });
        });

        setAllImages(images);
        setLoading(false);
        setIsFetchingMore(false);
      }
    );

    return allActiveDocsSnap;
  }, [
    userState,
    activeImages,
    isAsc,
    isPrompt,
    lastVisible,
    isDeleting,
    searchValueSet,
  ]);

  const handleEndReached = () => {
    if (lastVisible === null) {
      return;
    }
    if (lastVisible !== null && allImages.length < lastVisible) {
      return;
    }
    if (isFetchingMore === true) {
      return;
    }
    if (loading === true) {
      return;
    }
    setIsFetchingMore(true);
    setLastVisible(lastVisible + lastVisibleLength);
  };

  useEffect(() => {
    if (Number(window.innerWidth) < 500) {
      setLastVisible(6);
      setLastVisibleLength(6);
      return;
    }
    if (Number(window.innerWidth) < 1000 && Number(window.innerWidth) > 500) {
      setLastVisible(8);
      setLastVisibleLength(8);
      return;
    }
    if (Number(window.innerWidth) > 1000) {
      setLastVisible(9);
      setLastVisibleLength(9);
      return;
    }
  }, []);

  const checkIfChecked = (id: string) => {
    if (markedImages.includes(id)) {
      return true;
    }
    return false;
  };

  const handleCheckPress = (id: string) => {
    if (markedImages.includes(id)) {
      const newMarkedImages = markedImages.filter((item) => item !== id);
      setMarkedImages(newMarkedImages);
    } else {
      const newMarkedImages = [...markedImages, id];
      setMarkedImages(newMarkedImages);
    }
  };

  const deleteAllMarked = async () => {
    if (isDeleting) {
      return;
    }
    if (userState === null) {
      return;
    }
    setIsDeleting(true);
    for (let i = 0; i < markedImages.length; i++) {
      try {
        const docRef = doc(
          firestore,
          "generated",
          userState.uid,
          "generatedImages",
          markedImages[i]
        );
        await deleteDoc(docRef);
      } catch (error) {
        console.log(error);
        setDeleteOpen(false);
        setIsDeleting(false);
        setMarkedImages([]);
        setLastVisible(lastVisibleLength);
      }
    }
    setDeleteOpen(false);
    setIsDeleting(false);
    setMarkedImages([]);
    setLastVisible(lastVisibleLength);
  };

  const saveAllMarked = async () => {
    if (isSaving) {
      return;
    }
    if (userState === null) {
      return;
    }
    setIsSaving(true);
    for (let i = 0; i < markedImages.length; i++) {
      try {
        const fetchImage = await fetch(
          `https://firebasestorage.googleapis.com/v0/b/portraiture-app.appspot.com/o/generatedImages%2F${userState.uid}%2F${markedImages[i]}.jpeg?alt=media`
        );
        const blob = await fetchImage.blob();
        saveAs(blob, `portraiture-${markedImages[i]}.jpeg`);
      } catch (error) {
        console.log(error);
        setIsSaving(false);
        setMarkedImages([]);
      }
    }
    setIsSaving(false);
    setMarkedImages([]);
  };

  return !loading ? (
    <div className="Gallery">
      {allImages.length === 0 ? (
        <p
          className="LoginMessage"
          style={{ fontSize: "150%", fontWeight: "600" }}
        >
          No images found!
        </p>
      ) : (
        <div className="GalleryList">
          {allImages.map((image, index) => {
            return (
              <div
                key={image.id}
                className="GalleryItem"
              >
                <div
                  className="GalleryItemHeader"
                  onClick={() => handleCheckPress(image.id)}
                >
                  <p className="GalleryItemHeaderText">
                    {moment(image.generatedTimestamp).fromNow()}
                  </p>
                  <Checkbox
                    checked={checkIfChecked(image.id)}
                    onChange={() => handleCheckPress(image.id)}
                    sx={{
                      color: "#fff !important",
                      "& MuiCheckbox-indeterminate": { color: "#fff" },
                      "& Mui-checked": { color: "#fff" },
                    }}
                  />
                </div>
                <div className="GalleryItemImgOuter" onClick={() => navigate("/gallery/" + image.id)}>
                  <img
                    src={`https://firebasestorage.googleapis.com/v0/b/portraiture-app.appspot.com/o/generatedImages%2F${userState.uid}%2F${image.id}-thumb.jpeg?alt=media`}
                    alt="Generated"
                    className="GalleryItemImg"
                    loading="lazy"
                  />
                </div>
                <p className="GalleryItemText" onClick={() => navigate("/gallery/" + image.id)}>{image.prompt}</p>
              </div>
            );
          })}
        </div>
      )}
      <Waypoint scrollableAncestor={window} onEnter={handleEndReached}>
        {isFetchingMore ? (
          <div className="GalleryLoadMore">
            <CircularProgress size={30} sx={{ color: "#333" }} />
          </div>
        ) : lastVisible !== null && allImages.length < lastVisible ? (
          <div className="GalleryLoadMore">
            <p className="GalleryLoadMoreText">ALL IMAGES LOADED</p>
          </div>
        ) : (
          <div className="GalleryLoadMore">
            <p className="GalleryLoadMoreText">LOAD MORE</p>
          </div>
        )}
      </Waypoint>
      {markedImages.length > 0 ? (
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          color="white"
          sx={{
            position: "fixed",
            bottom: 10,
            right: 10,
            "& .MuiSpeedDial-fab": {
              backgroundColor: "#333",
              "&:hover": { backgroundColor: "black" },
            },
          }}
          icon={<SourceIcon />}
        >
          <SpeedDialAction
            key="Delete"
            icon={<DeleteOutlineIcon />}
            tooltipTitle={"Delete"}
            onClick={() => setDeleteOpen(true)}
          />
          <SpeedDialAction
            key="Save"
            icon={<DownloadIcon />}
            tooltipTitle={"Download"}
            onClick={saveAllMarked}
          />
        </SpeedDial>
      ) : (
        <SpeedDial
          ariaLabel="SpeedDial basic example"
          color="white"
          sx={{
            position: "fixed",
            bottom: 10,
            right: 10,
            "& .MuiSpeedDial-fab": {
              backgroundColor: "#333",
              "&:hover": { backgroundColor: "black" },
            },
          }}
          icon={<FilterListIcon />}
        >
          <SpeedDialAction
            key="Direction"
            icon={!isAsc ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            tooltipTitle={"Sort Direction"}
            onClick={() => setIsAsc(!isAsc)}
          />
          <SpeedDialAction
            key="Date"
            sx={{
              backgroundColor: !isPrompt
                ? "rgba(240,240,240,0.99)"
                : "rgba(240,240,240,0.99)",
            }}
            icon={isPrompt ? <AccessTimeIcon /> : <TextSnippetOutlinedIcon />}
            tooltipTitle={isPrompt ? "Sort by Date" : "Sort by Prompt"}
            onClick={() => setIsPrompt(!isPrompt)}
          />
        </SpeedDial>
      )}
      <Dialog
        open={deleteOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setDeleteOpen(false)}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: isDeleting ? "transparent" : "#222",
            color: "#fff",
          },
        }}
      >
        {isDeleting ? (
          <CircularProgress size={40} sx={{ color: "#fff" }} />
        ) : (
          <>
            <DialogTitle>Delete {markedImages.length} images?</DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ color: "#fff" }}>
                This action cannot be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="inherit" onClick={() => setDeleteOpen(false)}>
                CANCEL
              </Button>
              <Button color="error" onClick={deleteAllMarked}>
                DELETE
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  ) : (
    <div className="Gallery">
      <CircularProgress size={40} sx={{ color: "#333" }} />
    </div>
  );
}

export default Gallery;
