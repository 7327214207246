import React, { useState, useEffect } from "react";
import PortraitureIcon from "../../../components/ui/PortraitureIcon";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../components/redux/reduxHooks";
import { setMessageState } from "../../../components/redux/reduxSlice";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { signOut } from "firebase/auth";
import { firebaseAuth } from "../../../components/firebase/firebase";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import "./settingsPage.css";
import "../settings.css";

function SettingsPage() {
  const navigation = useNavigate();
  const userState = useAppSelector((state: any) => state.redux.user);
  const numberState = useAppSelector((state: any) => state.redux.numbers);
  const messageState = useAppSelector((state: any) => state.redux.message);
  const dispatch = useAppDispatch();
  const [shortLink, setShortLink] = useState("");

  useEffect(() => {
    if (userState === null || userState === undefined) {
      navigation("/settings");
    }
  }, [userState]);

  const getShortLink = async () => {
    try {
      const jsonValue = localStorage.getItem("shortLink");
      if (jsonValue !== null) {
        console.log(jsonValue);
        setShortLink(jsonValue);
      } else {
        const fetchShortLink = await fetch(
          "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=AIzaSyAiB1YV6JsNFzq2yGL7PbJUPgsdokvgNsM",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              dynamicLinkInfo: {
                link:
                  "https://link.portraiture.ai/ref?ref=" + numberState.refCode,
                domainUriPrefix: "https://link.portraiture.ai",
                navigationInfo: {
                  enableForcedRedirect: true,
                },
                iosInfo: {
                  iosBundleId: "ai.portraiture",
                  iosFallbackLink: "https://portraiture.ai",
                  iosAppStoreId: "6447111983",
                },
                androidInfo: {
                  androidPackageName: "ai.portraiture",
                  androidFallbackLink: "https://portraiture.ai",
                },
                socialMetaTagInfo: {
                  socialTitle: "Portraiture.ai",
                  socialDescription: "Join Portraiture.ai",
                  socialImageLink:
                    "https://firebasestorage.googleapis.com/v0/b/portraiture-ai.appspot.com/o/webImages%2Fsocial%2FlogoSocial.png?alt=media",
                },
              },
            }),
          }
        );
        const json = await fetchShortLink.json();
        const extractedLink = json.shortLink;
        console.log(json);
        setShortLink(extractedLink);
        localStorage.setItem("shortLink", extractedLink);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getShortLink();
  }, []);

  return (
    <div className="SettingsPage">
      <div className="SettingsHeader">
        <PortraitureIcon color="#333" />
      </div>
      <div className="SettingsPageInner">
        <div className="SettingsPageDivider"></div>
        <div className="SettingsPageDiv">
          <p className="SettingsPageDivTitle">USER ID</p>
          <div className="SettingsPageDivTextOuter">
            <p className="SettingsPageDivText">{userState.uid}</p>
          </div>
          <ContentCopyOutlinedIcon
            onClick={() => {
              navigator.clipboard.writeText(userState.uid);
              dispatch(
                setMessageState({
                  ...messageState,
                  open: true,
                  message: "User ID copied to clipboard",
                  duration: 3000,
                  background: "#222",
                  messageColor: "#fff",
                })
              );
            }}
            sx={{ fontSize: "160%", color: "#222", cursor: "pointer" }}
          />
        </div>
        <div className="SettingsPageDivider"></div>
        <div className="SettingsPageDiv">
          <p className="SettingsPageDivTitle">REFERRAL CODE</p>
          <div className="SettingsPageDivTextOuter">
            <p className="SettingsPageDivText">{numberState.refCode}</p>
          </div>
          <ContentCopyOutlinedIcon
            onClick={() => {
              navigator.clipboard.writeText(numberState.refCode);
              dispatch(
                setMessageState({
                  ...messageState,
                  open: true,
                  message: "Referral code copied to clipboard",
                  duration: 3000,
                  background: "#222",
                  messageColor: "#fff",
                })
              );
            }}
            sx={{ fontSize: "160%", color: "#222", cursor: "pointer" }}
          />
        </div>
        <div className="SettingsPageDiv">
          <p className="SettingsPageDivTitle">REFERRAL LINK</p>
          <div className="SettingsPageDivTextOuter">
            <p className="SettingsPageDivText">{shortLink}</p>
          </div>
          <ContentCopyOutlinedIcon
            onClick={() => {
              navigator.clipboard.writeText(shortLink);
              dispatch(
                setMessageState({
                  ...messageState,
                  open: true,
                  message: "Referral link copied to clipboard",
                  duration: 3000,
                  background: "#222",
                  messageColor: "#fff",
                })
              );
            }}
            sx={{ fontSize: "160%", color: "#222", cursor: "pointer" }}
          />
        </div>
        <div className="SettingsPageDivider"></div>
        <div className="SettingsPageDiv" style={{ cursor: "pointer" }}>
          <p className="SettingsPageDivTitle">RECEIPTS</p>
          <div className="SettingsPageDivTextOuter">
            <p className="SettingsPageDivText">
              View your funding and spending history
            </p>
          </div>
          <ArrowForwardIosOutlinedIcon
            sx={{ fontSize: "160%", color: "#222", cursor: "pointer" }}
          />
        </div>
        <div className="SettingsPageDivider"></div>
        <div className="SettingsPageDivider"></div>
        {userState?.providerData[0]?.providerId === "password" ? (
          <div
            className="SettingsPageDiv"
            style={{ cursor: "pointer" }}
            onClick={() => navigation("/settings/update")}
          >
            <p className="SettingsPageDivTitle">UPDATE PASSWORD</p>
            <div className="SettingsPageDivTextOuter">
              <p className="SettingsPageDivText">Change your password</p>
            </div>
            <ArrowForwardIosOutlinedIcon
              sx={{ fontSize: "160%", color: "#222", cursor: "pointer" }}
            />
          </div>
        ) : null}
        <div
          className="SettingsPageDiv"
          style={{ cursor: "pointer" }}
          onClick={() => navigation("/settings/delete")}
        >
          <p className="SettingsPageDivTitle">DELETE USER</p>
          <div className="SettingsPageDivTextOuter">
            <p className="SettingsPageDivText">
              Delete your account and/or data
            </p>
          </div>
          <ArrowForwardIosOutlinedIcon
            sx={{ fontSize: "160%", color: "#222", cursor: "pointer" }}
          />
        </div>
        <div className="SettingsPageDivider"></div>
        <div className="SettingsPageDivider"></div>
      </div>
      <div className="SettingsPageFooter">
        <Button
          sx={{
            width: "90%",
            maxWidth: "300px",
          }}
          variant="outlined"
          color="inherit"
          size="small"
          onClick={() => {
            signOut(firebaseAuth);
            navigation("/settings");
          }}
        >
          Log Out
        </Button>
      </div>
    </div>
  );
}

export default SettingsPage;
