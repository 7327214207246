import React, { useState, useEffect } from "react";
import type { PropsWithChildren } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import PortraitureIcon from "../../../components/ui/PortraitureIcon";
import PersonIcon from "@mui/icons-material/Person";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import SvgIcon from "@mui/material/SvgIcon";
import { useNavigate } from "react-router-dom";
import { firebaseAuth, firestore } from "../../../components/firebase/firebase";
import { collection, getCountFromServer } from "firebase/firestore";
import { calculateTimeShort } from "../../../components/utils/parsers";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import { useAppSelector } from "../../../components/redux/reduxHooks";
import "./dashboard.css";
import "../settings.css";

type tsProps = PropsWithChildren<{
  open: boolean;
}>;

let loadingCount = false;
function Dashboard({ open }: tsProps) {
  const userState = useAppSelector((state: any) => state.redux.user);
  const statusState = useAppSelector((state: any) => state.redux.status);
  const numberState = useAppSelector((state) => state.redux.numbers);

  const [activeCount, setActiveCount] = useState<number | null>(null);
  const [galleryCount, setGalleryCount] = useState<number | null>(null);
  const [medianQueue, setMedianQueue] = useState(0);
  const [serverCount, setServerCount] = useState(0);
  const [modelCount, setModelCount] = useState(0);

  const initCount = async () => {
    if (loadingCount === true) {
      return;
    }
    if (userState === null) {
      return;
    }
    if (galleryCount !== null && activeCount !== null) {
      return;
    }
    loadingCount = true;
    const coll = collection(
      firestore,
      "generated",
      userState.uid,
      "generatedImages"
    );
    const snapshot = await getCountFromServer(coll);
    console.log("count: ", snapshot.data().count);
    const coll1 = collection(
      firestore,
      "generated",
      userState.uid,
      "userImages"
    );
    const snapshot1 = await getCountFromServer(coll1);
    console.log("count: ", snapshot1.data().count);

    setGalleryCount(snapshot.data().count);
    setActiveCount(snapshot1.data().count);
    loadingCount = false;
  };

  const initMedianQueue = async () => {
    let tempNumbers = 0;
    for (let i = 0; i < statusState.services.length; i++) {
      tempNumbers += statusState.services[i].queueTime;
    }
    setMedianQueue(tempNumbers / statusState.services.length);
  };

  const calculateServers = () => {
    let tempServers = 0;
    for (let i = 0; i < statusState.services.length; i++) {
      if (
        statusState.services[i].autoOff === false &&
        statusState.services[i].manualOff === false
      ) {
        if (typeof statusState.services[i].gpu === "object") {
          tempServers += Object.values(statusState.services[i].gpu).length;
        } else {
          tempServers++;
        }
      }
    }
    setServerCount(tempServers);
  };

  const calculateModels = () => {
    let tempModels = 0;
    for (let i = 0; i < statusState.services.length; i++) {
      if (
        statusState.services[i].autoOff === false &&
        statusState.services[i].manualOff === false
      ) {
        tempModels++;
      }
    }
    setModelCount(tempModels);
  };

  useEffect(() => {
    initCount();
  }, []);

  useEffect(() => {
    initMedianQueue();
    calculateServers();
    calculateModels();
  }, [statusState]);

  return (
    <Accordion expanded={open} square>
      <AccordionSummary
        sx={{ height: "0px", display: "none" }}
      ></AccordionSummary>
      <AccordionDetails
        sx={{
          width: "100vw",
          backgroundColor: "#333",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "0px",
        }}
      >
        <div className="Dashboard">
          <div className="DashboardInner">
            <div className="DashboardIcon">
              <PortraitureIcon color="#5c9ead" />
            </div>
            <div className="DashboardInnerDiv">
              <div className="DashboardInnerDivInner">
                <p className="DashboardInnerDivTitle">Average queue</p>
                <p className="DashboardInnerDivText">
                  {calculateTimeShort(statusState.queueTime + 6)}
                </p>
              </div>
              <div className="DashboardInnerDivInner">
                <p className="DashboardInnerDivTitle">Active services</p>
                <p className="DashboardInnerDivText">
                  {serverCount}
                </p>
              </div>
              <div className="DashboardInnerDivInner">
                <p className="DashboardInnerDivTitle">Active models</p>
                <p className="DashboardInnerDivText">
                  {modelCount}
                </p>
              </div>
              <div className="DashboardInnerDivInner">
                <p className="DashboardInnerDivTitle">Total generated</p>
                <p className="DashboardInnerDivText">
                  {statusState.generated}
                </p>
              </div>
            </div>
          </div>
          <div className="DashboardInner">
            <div className="DashboardIcon">
              <PersonIcon
                sx={{ fontSize: "220%", color: "#5c9ead", marginLeft: "-3px" }}
              />
            </div>
            <div className="DashboardInnerDiv">
              <div className="DashboardInnerDivInner">
                <p className="DashboardInnerDivTitle">Tokens</p>
                <p className="DashboardInnerDivText">
                  {userState !== null && userState !== undefined
                    ? numberState.credits
                    : 0}
                </p>
              </div>
              <div className="DashboardInnerDivInner">
                <p className="DashboardInnerDivTitle">Generated</p>
                <p className="DashboardInnerDivText">
                  {userState !== null && userState !== undefined
                    ? numberState.generated
                    : 0}
                </p>
              </div>
              <div className="DashboardInnerDivInner">
                <p className="DashboardInnerDivTitle">Gallery</p>
                <p className="DashboardInnerDivText">
                  {galleryCount}
                </p>
              </div>
              <div className="DashboardInnerDivInner">
                <p className="DashboardInnerDivTitle">Active</p>
                <p className="DashboardInnerDivText">
                  {activeCount}
                </p>
              </div>
            </div>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

export default Dashboard;
