import React from 'react';
import './generator.css';

function Generator() {
  return (
    <div className="Generator">
      Generator
    </div>
  );
}

export default Generator;
