import React from "react";

import "./faq.css";
import "../../../App.css";

const listData = [
  {
    question: "What is Portraiture.ai?",
    answer:
      "Portraiture.ai is an AI-powered art generator that takes in either text prompts or images as input and produces unique artworks based on them.",
  },
  {
    question: "How does Portraiture.ai work?",
    answer:
      "Portraiture.ai uses a deep learning model that has been trained on a large dataset of artwork to understand the style and content of different art genres. It then generates new artworks based on the input it receives, using a combination of machine learning algorithms and creative algorithms.",
  },
  {
    question: "What kind of input can I use with Portraiture.ai?",
    answer:
      "You can use either text prompts or images as input with Portraiture.ai. For text prompts, you can input anything from a single word to a full paragraph describing the artwork you want. For images, you can either take a photo or upload an existing image.",
  },
  {
    question: "What kind of output can I expect from Portraiture.ai?",
    answer:
      "The output from Portraiture.ai will be a unique artwork generated by the AI based on the input you provided. The artwork will be in a specific style, based on the genre of art you selected. You can expect the output to be creative and different each time.",
  },
  {
    question: "Can I save and share the artwork generated by Portraiture.ai?",
    answer:
      "Yes, you can save the artwork generated by Portraiture.ai to your device and share it on social media or other platforms. ",
  },
  {
    question: "Is my data secure with Portraiture.ai?",
    answer:
      "Yes, your data is secure with Portraiture.ai. The app uses industry-standard encryption and security protocols to protect your data.",
  },
  {
    question: "What types of art genres can I choose from with Portraiture.ai?",
    answer:
      "Portraiture.ai offers a wide range of art genres to choose from, including but not limited to portraits, landscapes, abstract, and surrealism. Include any genre that you want in the input prompt",
  },
  {
    question:
      "How long does it take for Portraiture.ai to generate an artwork?",
    answer:
      "The time it takes for Portraiture.ai to generate an artwork depends on several factors, such as the complexity of the input and the workload on the servers. However, on average, it takes a few seconds to a minute to generate an artwork.",
  },
  {
    question: "Can I request specific details or features for my artwork?",
    answer:
      "Be as specific as you want in the input prompt. The AI will try to incorporate as many details as possible into the artwork. However, it is important to note that the AI is not perfect, and it may not be able to incorporate all the details you want into the artwork.",
  },
  {
    question:
      "Can I provide feedback on the artwork generated by Portraiture.ai?",
    answer: `Yes, you can provide feedback on the artwork generated by Portraiture.ai through the app's feedback feature. This feedback helps improve the app's AI model and algorithms for future use.`,
  },
  {
    question: "Can I use Portraiture.ai offline?",
    answer:
      "No, Portraiture.ai requires an internet connection to access the AI model and generate artworks. However, once an artwork is generated, you can save it to your device for offline use.",
  },
];

function Faq() {
  return (
    <div className="TemplateOuter">
      <div className="Faq">
        {listData.map((item, index) => (
          <div className="FaqItemOuter" key={index}>
            <div className="FaqItem">
              <p className="FaqItemTitle">{item.question}</p>
              <p className="FaqItemSubtitle">{item.answer}</p>
              
            </div>
            <div className="FaqDivider"></div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Faq;
