import React, { useState, useEffect } from "react";
import PortraitureIcon from "../../../components/ui/PortraitureIcon";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { firebaseAuth, firestore } from "../../../components/firebase/firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useAppSelector } from "../../../components/redux/reduxHooks";
import "./auth.css";
import "../settings.css";

function Signup() {
  const userState = useAppSelector((state: any) => state.redux.user);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [appleLoading, setAppleLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordInputRepeat, setPasswordInputRepeat] = useState("");
  const [refInput, setRefInput] = useState("");
  const [message, setMessage] = useState(
    "Let's get started! Enter your email and password. Password must be at least 8 characters long and contain at least one numeric digit, one uppercase and one lowercase letter."
  );
  const [messageEmail, setMessageEmail] = useState(false);
  const [messagePassword, setMessagePassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const navigation = useNavigate();

  const validateEmail = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(emailInput)) {
      setMessageEmail(true);
    } else {
      setMessageEmail(false);
    }
  };

  const validatePassword = () => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,60}$/;
    if (!passwordRegex.test(passwordInput)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    validateEmail();
  }, [emailInput]);
  useEffect(() => {
    setMessagePassword(validatePassword());
  }, [passwordInput]);

  const emailSignUp = async () => {
    setEmailLoading(true);
    try {
      const initSignin = await createUserWithEmailAndPassword(
        firebaseAuth,
        emailInput,
        passwordInput
      );
      console.log("success");
      console.log(initSignin);
      await sendEmailVerification(initSignin.user);
      if (refInput !== "") {
        await setDoc(doc(firestore, "tempReferrals", initSignin.user.uid), {
          refCode: refInput,
        });
      }
      return setEmailLoading(false);
    } catch (error: any) {
      console.log(error.code);
      if (error.code === "auth/email-already-in-use") {
        setMessage("The email address is already in use by another account.");
      } else {
        setMessage("Something went wrong, please try again");
      }
      return setEmailLoading(false);
    }
  };

  useEffect(() => {
    if (userState !== null && userState !== undefined) {
      navigation("/settings");
    }
  }, [userState]);

  return (
    <div className="Login">
      <div className="SettingsHeader">
        <PortraitureIcon color="#333" />
      </div>
      <p className="LoginMessage">{message}</p>
      <div className="LoginEmail">
        <TextField
          sx={textfieldStyle}
          label="Email"
          variant="outlined"
          value={emailInput}
          onChange={(event) => setEmailInput(event.target.value)}
          size="small"
          type="email"
        />
        <TextField
          sx={textfieldStyle}
          label="Password"
          variant="outlined"
          value={passwordInput}
          onChange={(event) => setPasswordInput(event.target.value)}
          size="small"
          type="password"
        />
        <TextField
          sx={textfieldStyle}
          label="Repeat Password"
          variant="outlined"
          value={passwordInputRepeat}
          onChange={(event) => setPasswordInputRepeat(event.target.value)}
          size="small"
          type="password"
        />
        <TextField
          sx={textfieldStyle}
          label="Referral Code (Optional)"
          variant="outlined"
          value={refInput}
          onChange={(event) => setRefInput(event.target.value)}
          size="small"
        />
        <Button
          sx={{
            width: "100%",
          }}
          variant="outlined"
          color="inherit"
          onClick={emailSignUp}
          disabled={
            messageEmail === true ||
            messagePassword === true ||
            passwordInput !== passwordInputRepeat
          }
        >
          {emailLoading === true ? (
            <CircularProgress size={22} color="inherit" />
          ) : (
            "Sign Up"
          )}
        </Button>
        <p className="LoginMessageText">
          By signing up, you agree to our{" "}
          <Link to={"/settings"} className="LoginMessageTextLink">
            Terms of Service{" "}
          </Link>
          &{" "}
          <Link to={"/settings"} className="LoginMessageTextLink">
            Privacy Policy
          </Link>
        </p>
      </div>
    </div>
  );
}

const textfieldStyle = {
  marginBottom: "16px",
  color: "#333",
  width: "100%",
  borderColor: "#333",
  justifySelf: "center",
  opacity: 0.8,
  "& label.Mui-focused": {
    color: "#333",
    borderColor: "#333",
  },
  "& .Mui-disabled": {
    color: "#333",
    borderColor: "#333",
  },
  "& .MuiInputLabel-root.Mui-disabled": {
    color: "#333",
  },
  "& .MuiOutlinedInput-root.Mui-disabled": {
    "& fieldset": {
      borderColor: "#333",
    },
    "& input": {
      borderColor: "#333",
      WebkitTextFillColor: "unset",
    },
    "& label": {
      color: "#333",
    },
    color: "#333",
    borderColor: "#333",
  },
  "& label": {
    color: "#333",
    borderColor: "#333",
  },
  "& textarea": {
    color: "#333",
    borderColor: "#333",
    width: "95%",
  },
  "& input": {
    color: "#333",
    borderColor: "#333",
  },
  "& .MuiInput-underline:after": {
    borderColor: "#333",
    color: "#333",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#333",
      color: "#333",
    },
    "&:hover fieldset": {
      borderColor: "#333",
      color: "#333",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#333",
      color: "#333",
    },
  },
  "@media (max-width: 800px)": {
    width: "100%",
  },
};

export default Signup;
