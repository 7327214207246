import React from "react";
import { useAppSelector } from "../../components/redux/reduxHooks";
import PortraitureIcon from "../../components/ui/PortraitureIcon";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import RedeemOutlinedIcon from "@mui/icons-material/RedeemOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Dashboard from "./dashboard/Dashboard";
import { useNavigate, Link } from "react-router-dom";

import "./settings.css";

function Settings() {
  const [open, setOpen] = React.useState(false);
  const userState = useAppSelector((state) => state.redux.user);
  const navigate = useNavigate();
  return (
    <div className="Settings">
      <div className="SettingsHeader">
        <PortraitureIcon color="#333" />
      </div>
      {userState !== null && userState !== undefined ? (
        <div className="SettingsLinks">
          <Dashboard open={open} />
          <div className="SettingsLink" onClick={() => setOpen(!open)}>
            <DashboardOutlinedIcon sx={{ fontSize: "160%" }} />
            <p className="SettingsLinkText">Dashboard</p>
            {!open ? (
              <ExpandMoreOutlinedIcon
                sx={{ fontSize: "240%", marginRight: "-4px" }}
              />
            ) : (
            <ExpandLessOutlinedIcon
              sx={{ fontSize: "240%", marginRight: "-4px" }}
            />
            )}
          </div>
          <div className="SettingsLink" onClick={() => navigate('/settings/account')}>
            <SettingsOutlinedIcon sx={{ fontSize: "160%" }} />
            <p className="SettingsLinkText">Settings</p>
            <ArrowForwardIosOutlinedIcon sx={{ fontSize: "160%" }} />
          </div>
          <div className="SettingsLink">
            <ShoppingCartOutlinedIcon sx={{ fontSize: "160%" }} />
            <p className="SettingsLinkText">Store</p>
            <ArrowForwardIosOutlinedIcon sx={{ fontSize: "160%" }} />
          </div>
          <div className="SettingsLink">
            <RedeemOutlinedIcon sx={{ fontSize: "160%" }} />
            <p className="SettingsLinkText">Redeem</p>
            <ArrowForwardIosOutlinedIcon sx={{ fontSize: "160%" }} />
          </div>
          <div className="SettingsLink" onClick={() => navigate('/settings/how')}>
            <SchoolOutlinedIcon sx={{ fontSize: "160%" }} />
            <p className="SettingsLinkText">How To</p>
            <ArrowForwardIosOutlinedIcon sx={{ fontSize: "160%" }} />
          </div>
          <div className="SettingsLink">
            <InfoOutlinedIcon sx={{ fontSize: "160%" }} />
            <p className="SettingsLinkText">Status</p>
            <ArrowForwardIosOutlinedIcon sx={{ fontSize: "160%" }} />
          </div>
        </div>
      ) : (
        <div className="SettingsLinks">
          <div className="SettingsLink" onClick={() => navigate('/settings/login')}>
            <AccountCircleOutlinedIcon sx={{ fontSize: "160%" }} />
            <p className="SettingsLinkText">Log In</p>
            <ArrowForwardIosOutlinedIcon sx={{ fontSize: "160%" }} />
          </div>
          <div className="SettingsLink">
            <SettingsOutlinedIcon sx={{ fontSize: "160%" }} />
            <p className="SettingsLinkText">Settings</p>
            <ArrowForwardIosOutlinedIcon sx={{ fontSize: "160%" }} />
          </div>
          <div className="SettingsLink" onClick={() => navigate('/settings/how')}>
            <SchoolOutlinedIcon sx={{ fontSize: "160%" }} />
            <p className="SettingsLinkText">How To</p>
            <ArrowForwardIosOutlinedIcon sx={{ fontSize: "160%" }} />
          </div>
          <div className="SettingsLink">
            <InfoOutlinedIcon sx={{ fontSize: "160%" }} />
            <p className="SettingsLinkText">Status</p>
            <ArrowForwardIosOutlinedIcon sx={{ fontSize: "160%" }} />
          </div>
        </div>
      )}
       <div className="SettingsFooter">
        <p className="LoginMessageText">
            <Link to={"/settings/terms"} className="LoginMessageTextLink">
              Terms of Service{" "}
            </Link>
            &{" "}
            <Link to={"/settings/privacy"} className="LoginMessageTextLink">
              Privacy Policy
            </Link>
          </p>
       </div>
    </div>
  );
}

export default Settings;
