import React, { useState, useEffect } from "react";
import PortraitureIcon from "../../../components/ui/PortraitureIcon";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import SvgIcon from "@mui/material/SvgIcon";
import { useNavigate } from "react-router-dom";
import { firebaseAuth } from "../../../components/firebase/firebase";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
} from "firebase/auth";
import { useAppSelector } from "../../../components/redux/reduxHooks";
import "./auth.css";
import "../settings.css";

function Login() {
  const [googleLoading, setGoogleLoading] = useState(false);
  const [appleLoading, setAppleLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [message, setMessage] = useState("");
  const [messageEmail, setMessageEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const navigation = useNavigate();
  const userState = useAppSelector((state: any) => state.redux.user);

  const validateEmail = () => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!emailRegex.test(emailInput)) {
      setMessageEmail(true);
    } else {
      setMessageEmail(false);
    }
  };

  const emailSignIn = async () => {
    setEmailLoading(true);
    try {
      const initSignin = await signInWithEmailAndPassword(
        firebaseAuth,
        emailInput,
        passwordInput
      );
      console.log("success");
      console.log(initSignin);
      return setEmailLoading(false);
    } catch (error: any) {
      console.log(error.code);
      if (error.code === "auth/user-not-found") {
        setMessage("Incorrect email or password");
        setPasswordError(true);
      } else if (error.code === "auth/wrong-password") {
        setMessage("Incorrect email or password");
        setPasswordError(true);
      } else {
        setMessage("Something went wrong, please try again");
      }
      return setEmailLoading(false);
    }
  };

  const googleSignIn = async () => {
    setGoogleLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      const initSignin = await signInWithPopup(firebaseAuth, provider);
      console.log("success");
      console.log(initSignin);
      return setGoogleLoading(false);
    } catch (error: any) {
      console.log(error);
      console.log(error.code);
      if (error.code === "auth/user-not-found") {
        setMessage("Incorrect email or password");
        setPasswordError(true);
      } else if (error.code === "auth/cancelled-popup-request") {
        return setGoogleLoading(false);
      } else {
        setMessage("Something went wrong, please try again");
      }
      return setGoogleLoading(false);
    }
  };

  const appleSignin = async () => {
    setAppleLoading(true);
    try {
      const provider = new OAuthProvider('apple.com');
      const initSignin = await signInWithPopup(firebaseAuth, provider);
      console.log("success");
      console.log(initSignin);
      return setAppleLoading(false);
    } catch (error: any) {
      console.log(error);
      console.log(error.code);
      if (error.code === "auth/user-not-found") {
        setMessage("Incorrect email or password");
        setPasswordError(true);
      } else if (error.code === "auth/cancelled-popup-request") {
        return setAppleLoading(false);
      } else if (error.code === "auth/popup-closed-by-user") {
        return setAppleLoading(false);
      } else {
        setMessage("Something went wrong, please try again");
      }
      return setAppleLoading(false);
    }
  };

  useEffect(() => {
    validateEmail();
  }, [emailInput]);

  useEffect(() => {
    if (userState !== null && userState !== undefined) {
      navigation("/settings");
    }
  }, [userState]);

  return (
    <div className="Login">
      <div className="SettingsHeader">
        <PortraitureIcon color="#333" />
      </div>
      <p className="LoginMessage">{message}</p>
      <div className="LoginEmail">
        <TextField
          sx={textfieldStyle}
          label="Email"
          variant="outlined"
          value={emailInput}
          onChange={(event) => setEmailInput(event.target.value)}
          size="small"
          type="email"
        />
        <TextField
          sx={textfieldStyle}
          label="Password"
          variant="outlined"
          value={passwordInput}
          onChange={(event) => setPasswordInput(event.target.value)}
          size="small"
          type="password"
        />
        <Button
          sx={{
            width: "100%",
          }}
          variant="outlined"
          color="inherit"
          disabled={messageEmail === true || passwordInput.length < 8}
          onClick={emailSignIn}
        >
          {emailLoading === true ? (
            <CircularProgress size={22} color="inherit" />
          ) : (
            "Log In"
          )}
        </Button>
        {passwordError === true ? (
          <p className="LoginMessageText">
            Forgot your password? {""}
            <Link to={"/settings/reset"} className="LoginMessageTextLink">
              Reset Password{" "}
            </Link>
          </p>
        ) : (
          <p className="LoginMessageText">
            Don't have an account? {""}
            <Link to={"/settings/register"} className="LoginMessageTextLink">
              Sign up{" "}
            </Link>
          </p>
        )}
      </div>
      <p className="LoginMessage" style={{ fontSize: "110%" }}>
        or
      </p>
      <div className="LoginProviders">
        <Button
          sx={{
            width: "100%",
            backgroundColor: "#333",
            color: "#fff",
            borderColor: "#333",
            marginTop: "10px",
            marginBottom: "10px",
          }}
          onClick={googleSignIn}
          size="large"
          variant="outlined"
          color="inherit"
          startIcon={
            <SvgIcon fontSize="small" sx={{ overflow: "visible" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-3 0 27 26"
                style={{ overflow: "visible" }}
              >
                <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
                  <path
                    fill="#4285F4"
                    d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
                  />
                  <path
                    fill="#34A853"
                    d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
                  />
                  <path
                    fill="#FBBC05"
                    d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
                  />
                  <path
                    fill="#EA4335"
                    d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
                  />
                </g>
              </svg>
            </SvgIcon>
          }
        >
          Log in with Google
        </Button>
        <Button
          sx={{
            width: "100%",
            backgroundColor: "#333",
            color: "#fff",
            borderColor: "#333",
            marginTop: "10px",
            marginBottom: "10px",
          }}
          onClick={appleSignin}
          size="large"
          variant="outlined"
          color="inherit"
          startIcon={
            <SvgIcon fontSize="small" sx={{ overflow: "visible" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 2 23.58 28"
                style={{ overflow: "visible" }}
              >
                <path
                  fill="#fff"
                  d="M26.3 22.82a15.76 15.76 0 01-1.51 2.71 13.38 13.38 0 01-1.94 2.34A3.75 3.75 0 0120.36 29a6.17 6.17 0 01-2.3-.55 6.54 6.54 0 00-2.48-.55 6.79 6.79 0 00-2.55.55 6.83 6.83 0 01-2.2.58 3.57 3.57 0 01-2.55-1.13 14.26 14.26 0 01-2-2.43 16.66 16.66 0 01-2.14-4.26 15.56 15.56 0 01-.9-5.07 9.17 9.17 0 011.22-4.85A7 7 0 017 8.68a6.75 6.75 0 013.44-1 8.13 8.13 0 012.67.62 8.5 8.5 0 002.09.7 12.6 12.6 0 002.35-.74 7.69 7.69 0 013.19-.56A6.78 6.78 0 0126 10.44a5.89 5.89 0 00-3.13 5.36 5.93 5.93 0 001.94 4.45 6.44 6.44 0 001.94 1.27c-.15.45-.32.89-.49 1.3zM20.9 1.56a6 6 0 01-1.54 3.92A5.2 5.2 0 0115 7.62a4.58 4.58 0 010-.53 6.1 6.1 0 011.62-4 6.29 6.29 0 012-1.49 6 6 0 012.25-.6 5.25 5.25 0 010 .56z"
                  transform="translate(-3.21 -1)"
                ></path>
              </svg>
            </SvgIcon>
          }
        >
          Log in with Apple
        </Button>
      </div>
    </div>
  );
}

const textfieldStyle = {
  marginBottom: "16px",
  color: "#333",
  width: "100%",
  borderColor: "#333",
  justifySelf: "center",
  opacity: 0.8,
  "& label.Mui-focused": {
    color: "#333",
    borderColor: "#333",
  },
  "& .Mui-disabled": {
    color: "#333",
    borderColor: "#333",
  },
  "& .MuiInputLabel-root.Mui-disabled": {
    color: "#333",
  },
  "& .MuiOutlinedInput-root.Mui-disabled": {
    "& fieldset": {
      borderColor: "#333",
    },
    "& input": {
      borderColor: "#333",
      WebkitTextFillColor: "unset",
    },
    "& label": {
      color: "#333",
    },
    color: "#333",
    borderColor: "#333",
  },
  "& label": {
    color: "#333",
    borderColor: "#333",
  },
  "& textarea": {
    color: "#333",
    borderColor: "#333",
    width: "95%",
  },
  "& input": {
    color: "#333",
    borderColor: "#333",
  },
  "& .MuiInput-underline:after": {
    borderColor: "#333",
    color: "#333",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#333",
      color: "#333",
    },
    "&:hover fieldset": {
      borderColor: "#333",
      color: "#333",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#333",
      color: "#333",
    },
  },
  "@media (max-width: 800px)": {
    width: "100%",
  },
};

export default Login;
