import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Button from "@mui/material/Button";
import { SnackbarContent } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "@mui/material";

import { setMessageState } from "../redux/reduxSlice";

import "./ui.css";
export default function SnackbarTemplate() {
  const messageState = useSelector((state: any) => state.redux.message);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(
      setMessageState({
        ...messageState,
        open: false,
        internalLink: false,
        link: undefined,
        linkMessage: "",
        storageName: "",
        storageData: undefined,
      })
    );
  };

  const handleCloseLocalStorage = () => {
    
    localStorage.setItem(
      messageState.storageName,
      JSON.stringify(messageState.storageData)
    );
    dispatch(
      setMessageState({
        ...messageState,
        open: false,
        internalLink: false,
        link: undefined,
        linkMessage: "",
        storageName: "",
        storageData: undefined,
        storageLink: "",
        message: "",
      })
    );
  };

  const action = (
    <React.Fragment>
      {messageState.link !== undefined ? (
        <Link
          underline="none"
          href={messageState.link}
          target="_blank"
          rel="noopener"
          sx={{ color: "rgba(43, 43, 43, 0.9)" }}
        >
          {messageState.linkMessage}
        </Link>
      ) : messageState.storageName !== "" ? (
        <Button
          size="small"
          sx={{ color: messageState.linkColor}}
          onClick={() => handleCloseLocalStorage()}
        >
          {messageState.storageLink}
        </Button>
      ) : (
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => handleClose()}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      )}
    </React.Fragment>
  );

  return (
    <Snackbar
        open={messageState.open}
        autoHideDuration={messageState.duration}
        onClose={() => handleClose()}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <SnackbarContent
          message={messageState.message}
          action={action}
          sx={{
            zIndex: 8000,
            backgroundColor: messageState.background,
            color: messageState.color,
            fontFamily: "Poppins",
            fontWeight: "500",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: messageState.background,
            borderRadius: "3px",
            textAlign: "left",
            flex: 1,
          }}
        />
      </Snackbar>
  );
}
