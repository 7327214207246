import React, { useState, useEffect } from "react";
import PortraitureIcon from "../../../components/ui/PortraitureIcon";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { firebaseAuth, firestore } from "../../../components/firebase/firebase";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updatePassword,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useAppSelector, useAppDispatch } from "../../../components/redux/reduxHooks";
import { setMessageState } from "../../../components/redux/reduxSlice";
import "./auth.css";
import "../settings.css";

function UpdatePassword() {
  const userState = useAppSelector((state: any) => state.redux.user);
  const messageState = useAppSelector((state: any) => state.redux.message);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [appleLoading, setAppleLoading] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordInputRepeat, setPasswordInputRepeat] = useState("");
  const [refInput, setRefInput] = useState("");
  const [message, setMessage] = useState(
    "Enter your new password. Password must be at least 8 characters long and contain at least one numeric digit, one uppercase and one lowercase letter."
  );
  const [messageEmail, setMessageEmail] = useState(false);
  const [messagePassword, setMessagePassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const navigation = useNavigate();
  const dispatch = useAppDispatch();

  const validatePassword = () => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,60}$/;
    if (!passwordRegex.test(passwordInput)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setMessagePassword(validatePassword());
  }, [passwordInput]);

  const onUpdatePassword = async () => {
    if (passwordInput !== passwordInputRepeat) {
      setMessage("Passwords do not match.");
      return;
    }
    if (userState === null) {
      return;
    }
    if (validatePassword() === true) {
      setMessage("Incorrect Passwords");
      return;
    }

    setEmailLoading(true);
    try {
      const initUpdate = await updatePassword(userState, passwordInput);
      console.log("success");
      console.log(initUpdate);
      setEmailLoading(false);
      dispatch(
          setMessageState({
            ...messageState,
            open: true,
            message: "Password updated successfully.",
            duration: 5000,
            background: "#222",
            messageColor: "#fff",
          })
        );
      return navigation("/settings/account");
    } catch (error: any) {
        if (error.code === 'auth/requires-recent-login') {
                setEmailLoading(false);
                setMessage(
                  'This operation is sensitive and requires recent authentication. Please login again to update your password.',
                );
                return console.log(error.message);
              } else {
                setEmailLoading(false);
                setMessage('Something went wrong. Please try again.');
                return console.log('Something went wrong. Please try again.');
              }
    }
  };

  return (
    <div className="Login">
      <div className="SettingsHeader">
        <PortraitureIcon color="#333" />
      </div>
      <p className="LoginMessage">{message}</p>
      <div className="LoginEmail">
        <TextField
          sx={textfieldStyle}
          label="New password"
          variant="outlined"
          value={passwordInput}
          onChange={(event) => setPasswordInput(event.target.value)}
          size="small"
          type="password"
        />
        <TextField
          sx={textfieldStyle}
          label="Repeat new password"
          variant="outlined"
          value={passwordInputRepeat}
          onChange={(event) => setPasswordInputRepeat(event.target.value)}
          size="small"
          type="password"
        />
        <Button
          sx={{
            width: "100%",
          }}
          variant="outlined"
          color="inherit"
          onClick={onUpdatePassword}
          disabled={
            messagePassword === true || passwordInput !== passwordInputRepeat
          }
        >
          {emailLoading === true ? (
            <CircularProgress size={22} color="inherit" />
          ) : (
            "Reset Password"
          )}
        </Button>
      </div>
    </div>
  );
}

const textfieldStyle = {
  marginBottom: "16px",
  color: "#333",
  width: "100%",
  borderColor: "#333",
  justifySelf: "center",
  opacity: 0.8,
  "& label.Mui-focused": {
    color: "#333",
    borderColor: "#333",
  },
  "& .Mui-disabled": {
    color: "#333",
    borderColor: "#333",
  },
  "& .MuiInputLabel-root.Mui-disabled": {
    color: "#333",
  },
  "& .MuiOutlinedInput-root.Mui-disabled": {
    "& fieldset": {
      borderColor: "#333",
    },
    "& input": {
      borderColor: "#333",
      WebkitTextFillColor: "unset",
    },
    "& label": {
      color: "#333",
    },
    color: "#333",
    borderColor: "#333",
  },
  "& label": {
    color: "#333",
    borderColor: "#333",
  },
  "& textarea": {
    color: "#333",
    borderColor: "#333",
    width: "95%",
  },
  "& input": {
    color: "#333",
    borderColor: "#333",
  },
  "& .MuiInput-underline:after": {
    borderColor: "#333",
    color: "#333",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#333",
      color: "#333",
    },
    "&:hover fieldset": {
      borderColor: "#333",
      color: "#333",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#333",
      color: "#333",
    },
  },
  "@media (max-width: 800px)": {
    width: "100%",
  },
};

export default UpdatePassword;
