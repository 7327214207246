import React from "react";
import { useAppSelector } from "../../../components/redux/reduxHooks";
import PortraitureIcon from "../../../components/ui/PortraitureIcon";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import TokenOutlinedIcon from '@mui/icons-material/TokenOutlined';
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import ExpandLessOutlinedIcon from "@mui/icons-material/ExpandLessOutlined";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import RedeemOutlinedIcon from "@mui/icons-material/RedeemOutlined";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Dashboard from "../dashboard/Dashboard";
import QuestionMarkOutlinedIcon from '@mui/icons-material/QuestionMarkOutlined';
import { useNavigate, Link } from "react-router-dom";

import "../settings.css";

function HowMenu() {
  const [open, setOpen] = React.useState(false);
  const userState = useAppSelector((state) => state.redux.user);
  const navigate = useNavigate();
  return (
    <div className="Settings">
      <div className="SettingsHeader">
        <PortraitureIcon color="#333" />
      </div>
      <div className="SettingsLinks">
          <div className="SettingsLink" onClick={() => navigate('/settings/how/tokens')}>
            <TokenOutlinedIcon sx={{ fontSize: "160%" }} />
            <p className="SettingsLinkText">Tokens</p>
            <ArrowForwardIosOutlinedIcon sx={{ fontSize: "160%" }} />
          </div>
          <div className="SettingsLink" onClick={() => navigate('/settings/how/learn')}>
            <SchoolOutlinedIcon sx={{ fontSize: "160%" }} />
            <p className="SettingsLinkText">University</p>
            <ArrowForwardIosOutlinedIcon sx={{ fontSize: "160%" }} />
          </div>
          <div className="SettingsLink" onClick={() => navigate('/settings/how/faq')}>
            <QuestionMarkOutlinedIcon sx={{ fontSize: "160%" }} />
            <p className="SettingsLinkText">FAQ</p>
            <ArrowForwardIosOutlinedIcon sx={{ fontSize: "160%" }} />
          </div>
        </div>
       <div className="SettingsFooter">
        <p className="LoginMessageText">
            <Link to={"/settings/terms"} className="LoginMessageTextLink">
              Terms of Service{" "}
            </Link>
            &{" "}
            <Link to={"/settings/privacy"} className="LoginMessageTextLink">
              Privacy Policy
            </Link>
          </p>
       </div>
    </div>
  );
}

export default HowMenu;
