import * as React from "react";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import CameraRoundedIcon from "@mui/icons-material/CameraRounded";
import PhotoRoundedIcon from "@mui/icons-material/PhotoRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import { useNavigate, useLocation } from "react-router-dom";
import Badge from "@mui/material/Badge";
import { useAppSelector } from "../redux/reduxHooks";

export default function NavigationTab() {
  const userState = useAppSelector((state: any) => state.redux.user);
  const numbersState = useAppSelector((state: any) => state.redux.numbers);
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = React.useState("generator");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if (newValue === "generator") {
      navigate("/");
    } else {
      navigate(`/${newValue}`);
    }
  };

  React.useEffect(() => {
    let pathValue = location.pathname.split("/")[1];
    console.log(pathValue);
    if (pathValue === "") {
      pathValue = "generator";
    }
    if (pathValue === value) {
      return;
    }
    setValue(pathValue);
  }, [location]);
  return (
    <Paper
    square
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        backgroundColor: "#333",
        paddingTop: '8px',
      }}
      elevation={1}
    >
      <BottomNavigation
        showLabels={false}
        value={value}
        onChange={handleChange}
        sx={{ backgroundColor: "#333" }}
      >
        <BottomNavigationAction
          sx={{
            color: value === "generator" ? "#A4BDCE" : "#fff",
            opacity: value === "generator" ? 1 : 0.6,
            "& .Mui-selected": {
              color: value === "generator" ? "#A4BDCE" : "#fff",
              opacity: value === "generator" ? 1 : 0.6,
            },
          }}
          label="Generator"
          value="generator"
          icon={
            <Badge
              max={100000000}
              badgeContent={
                userState !== null && Number(numbersState.credits) > 0
                  ? numbersState.credits
                  : undefined
              }
              sx={{
                color: value === "generator" ? "#A4BDCE" : "#fff",
                opacity: value === "generator" ? 1 : 0.6,
                "& .MuiBadge-badge": {
                  backgroundColor: "#333",
                },
              }}
            >
              <CameraRoundedIcon
                sx={{
                  color: value === "generator" ? "#A4BDCE" : "#fff",
                  opacity: value === "generator" ? 1 : 0.6,
                }}
              />
            </Badge>
          }
        />
        <BottomNavigationAction
          sx={{
            color: value === "gallery" ? "#A4BDCE" : "#fff",
            opacity: value === "gallery" ? 1 : 0.6,
            "& .Mui-selected": {
              color: value === "gallery" ? "#A4BDCE" : "#fff",
              opacity: value === "gallery" ? 1 : 0.6,
            },
          }}
          label="Gallery"
          value="gallery"
          icon={
            <PhotoRoundedIcon
              sx={{
                color: value === "gallery" ? "#A4BDCE" : "#fff",
                opacity: value === "gallery" ? 1 : 0.6,
              }}
            />
          }
        />
        <BottomNavigationAction
          sx={{
            color: value === "settings" ? "#A4BDCE" : "#fff",
            opacity: value === "settings" ? 1 : 0.6,
            "& .Mui-selected": {
              color: value === "settings" ? "#A4BDCE" : "#fff",
              opacity: value === "settings" ? 1 : 0.6,
            },
          }}
          label="Settings"
          value="settings"
          icon={
            <SettingsRoundedIcon
              sx={{
                color: value === "settings" ? "#A4BDCE" : "#fff",
                opacity: value === "settings" ? 1 : 0.6,
              }}
            />
          }
        />
      </BottomNavigation>
    </Paper>
  );
}
