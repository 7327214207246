import React, { useState, useEffect } from "react";
import { firebaseAuth, firestore } from "../../components/firebase/firebase";
import Button from "@mui/material/Button";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkbox from "@mui/material/Checkbox";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import PortraitureIcon from "../../components/ui/PortraitureIcon";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";
import Slide from "@mui/material/Slide";
import { saveAs } from "file-saver";
import { setMessageState } from "../../components/redux/reduxSlice";
import { TransitionProps } from "@mui/material/transitions";
import { promptModels, imageProccessors } from "../../components/utils/enums";
import { calculateTime } from "../../components/utils/parsers";
import {
  doc,
  setDoc,
  collection,
  getDocs,
  deleteDoc,
  where,
  query,
  orderBy,
  limit,
  startAfter,
  endAt,
  startAt,
  getDoc,
  onSnapshot,
  OrderByDirection,
} from "firebase/firestore";
import {
  useAppSelector,
  useAppDispatch,
} from "../../components/redux/reduxHooks";
import { useSpring, animated } from "@react-spring/web";
import "./gallery.css";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { Waypoint } from "react-waypoint";
import { set } from "firebase/database";
import { get } from "http";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function GalleryItem() {
  const userState = useAppSelector((state: any) => state.redux.user);
  const messageState = useAppSelector((state: any) => state.redux.message);
  const [loading, setLoading] = useState(true);
  const [activeImages, setActiveImages] = useState(false);
  const [isAsc, setIsAsc] = useState(false);
  const [isPrompt, setIsPrompt] = useState(false);
  const [allImages, setAllImages] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [markedImages, setMarkedImages] = useState<string[]>([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [lastVisible, setLastVisible] = useState<number | null>(null);
  const [lastVisibleLength, setLastVisibleLength] = useState(0);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [searchValueSet, setSearchValueSet] = useState("");
  const [listOrder, setListOrder] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [receiptOpen, setReceiptOpen] = useState(false);

  const [loaded, setLoaded] = useState(false);
  const [metadata, setMetadata] = useState<any>(null);
  const [imageId, setImageId] = useState<string | null>(null);
  const [models, setModels] = useState<any>([]);

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const tempModels = Object.values(promptModels);
    setModels(tempModels);
  }, []);

  useEffect(() => {
    if (params.imageID) {
      setImageId(params.imageID);
    } else {
      return navigate("/gallery");
    }
  }, []);

  useEffect(() => {
    if (!metadata && imageId) {
      try {
        const docRef = doc(
          firestore,
          "generated",
          userState.uid,
          "generatedImages",
          imageId
        );
        const docSnap = getDoc(docRef);
        docSnap.then((doc) => {
          if (doc.exists()) {
            setMetadata(doc.data());
            setLoaded(true);
          } else {
            return navigate("/gallery");
          }
        });
      } catch (error) {
        console.log(error);
        return navigate("/gallery");
      }
    }
  }, [metadata, imageId]);

  const deleteImage = async () => {
    if (isDeleting) {
      return;
    }
    if (userState === null) {
      return;
    }
    setIsDeleting(true);
    try {
      const docRef = doc(
        firestore,
        "generated",
        userState.uid,
        "generatedImages",
        metadata.id
      );
      await deleteDoc(docRef);
      setIsDeleting(false);
      return navigate("/gallery");
    } catch (error) {
      console.log(error);
      setDeleteOpen(false);
      setIsDeleting(false);
      dispatch(
        setMessageState({
          ...messageState,
          message: "Something went wrong. Please try again.",
          error: true,
          open: true,
          duration: 5000,
        })
      );
    }
  };

  const saveImage = async () => {
    if (isSaving) {
      return;
    }
    if (userState === null) {
      return;
    }
    setIsSaving(true);
    try {
        const fetchImage = await fetch(
          `https://firebasestorage.googleapis.com/v0/b/portraiture-app.appspot.com/o/generatedImages%2F${metadata.uid}%2F${metadata.id}.jpeg?alt=media`
        );
        const blob = await fetchImage.blob();
        saveAs(blob, `portraiture-${metadata.id}.jpeg`);
      } catch (error) {
        console.log(error);
        setIsSaving(false);
      }
    setIsSaving(false);
  };

  return loaded ? (
    <div className="Gallery">
      <div className="GalleryItemPage">
        <div className="GalleryItemImgOuter">
          <img
            src={`https://firebasestorage.googleapis.com/v0/b/portraiture-app.appspot.com/o/generatedImages%2F${metadata.uid}%2F${metadata.id}-thumb.jpeg?alt=media`}
            alt="Generated"
            className="GalleryItemImg"
            loading="lazy"
          />
        </div>
        <div className="GalleryItemPageMain">
          <p className="GalleryItemPageMainTitle">PROMPT</p>
          <p className="GalleryItemPageMainText">{metadata.prompt}</p>
        </div>
        {metadata.chatGPT === true ? (
          <div className="GalleryItemPageMain">
            <p className="GalleryItemPageMainTitle">CHATGPT PROMPT</p>
            <p className="GalleryItemPageMainText">{metadata.chatGPTPrompt}</p>
          </div>
        ) : null}
        {metadata.aiModel !== "dalle" ? (
          <div className="GalleryItemPageMain">
            <p className="GalleryItemPageMainTitle">SEED</p>
            <p className="GalleryItemPageMainText">{metadata.seed}</p>
          </div>
        ) : null}
        {metadata.aiModel !== "dalle" ? (
          <div className="GalleryItemPageMain">
            <p className="GalleryItemPageMainTitle">TEXT ATTENTION</p>
            <p className="GalleryItemPageMainText">
              {Math.ceil(metadata.text_attention / 1.5)}
            </p>
          </div>
        ) : null}
        {metadata.storageTempUrl !== "" ? (
          <div className="GalleryItemPageMain">
            <p className="GalleryItemPageMainTitle">IMAGE ATTENTION</p>
            <p className="GalleryItemPageMainText">
              {Math.round(21 - metadata.image_attention * 20)}
            </p>
          </div>
        ) : null}
        <div className="GalleryItemPageMain">
          <p className="GalleryItemPageMainTitle">AI MODEL</p>
          <p className="GalleryItemPageMainText">
            {
              models.find((model: any) => {
                return model.value === metadata.aiModel;
              })?.label
            }
          </p>
        </div>
        {metadata.storageTempUrl !== "" ? (
          <div className="GalleryItemPageMain">
            <p className="GalleryItemPageMainTitle">IMAGE PROCCESSOR</p>
            <p className="GalleryItemPageMainText">
              {
                imageProccessors.find((model: any) => {
                  return model.value === metadata.poseModel;
                })?.label
              }
            </p>
          </div>
        ) : null}
        <div className="GalleryItemPageMain">
          <p className="GalleryItemPageMainTitle">SIZE</p>
          <p className="GalleryItemPageMainText">
            {metadata.width * 2} x {metadata.height * 2}
          </p>
        </div>
        <div className="GalleryItemPageMain">
          <p className="GalleryItemPageMainTitle">GENERATE TIME WITH QUEUE</p>
          <p className="GalleryItemPageMainText">
            {calculateTime(
              Math.floor(
                (metadata.generatedTimestamp - metadata.timestamp) / 1000
              )
            )}
          </p>
        </div>
        <div className="GalleryItemPageMain">
          <p
            className="GalleryItemPageMainTitle"
            style={{ borderBottom: "none" }}
          >
            WHEN
          </p>
          <p className="GalleryItemPageMainText">
            {moment(metadata.generatedTimestamp).calendar()}
          </p>
        </div>
        <div className="GalleryItemPageMainEnd"></div>

        <div className="GalleryItemPageIcons">
          <div className="GalleryItemPageIcon">
            <DownloadIcon
              onClick={saveImage}
              sx={{ color: "#F3F3F3", fontSize: "220%", cursor: "pointer" }}
            />
          </div>
          <div className="GalleryItemPageIcon">
            <ContentCopyOutlinedIcon
              sx={{ color: "#F3F3F3", fontSize: "195%", cursor: "pointer" }}
            />
          </div>
          <div className="GalleryItemPageIcon">
            <ReceiptLongOutlinedIcon
              onClick={() => setReceiptOpen(true)}
              sx={{ color: "#F3F3F3", fontSize: "210%", cursor: "pointer" }}
            />
          </div>
          <div className="GalleryItemPageIcon">
            <DeleteOutlineIcon
              onClick={() => setDeleteOpen(true)}
              sx={{ color: "#F3F3F3", fontSize: "220%", cursor: "pointer" }}
            />
          </div>
        </div>
      </div>
      <Dialog
        open={deleteOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setDeleteOpen(false)}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: isDeleting ? "transparent" : "#222",
            color: "#fff",
          },
        }}
      >
        {isDeleting ? (
          <CircularProgress size={40} sx={{ color: "#fff" }} />
        ) : (
          <>
            <DialogTitle>Delete image?</DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ color: "#fff" }}>
                This action cannot be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="inherit" onClick={() => setDeleteOpen(false)}>
                CANCEL
              </Button>
              <Button color="error" onClick={deleteImage}>
                DELETE
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Dialog
        open={receiptOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setReceiptOpen(false)}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "#5C9EAD",
            color: "#222",
            minWidth: "250px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          },
        }}
      >
        <div className="GalleryItemPageMainIcon">
          <PortraitureIcon color="#333" />
        </div>
        <p className="GalleryItemPageDialogText">
          {
            models.find((model: any) => {
              return model.value === metadata.aiModel;
            })?.label
          }
        </p>
        <div className="GalleryItemPageDialogInfo">
          <p className="GalleryItemPageDialogInfoTitle">WHEN:</p>
          <p className="GalleryItemPageDialogInfoText">
            {moment(metadata.generatedTimestamp).calendar()}
          </p>
        </div>
        <div className="GalleryItemPageDialogInfo">
          <p className="GalleryItemPageDialogInfoTitle">DATE:</p>
          <p className="GalleryItemPageDialogInfoText">
            {moment(metadata.timestamp).format("YYYY/MM/DD HH:mm")}
          </p>
        </div>
        <div className="GalleryItemPageDialogInfo">
          <p className="GalleryItemPageDialogInfoTitle">AI MODEL:</p>
          <p className="GalleryItemPageDialogInfoText">
            {metadata.replicate ? "+2 tokens" : "+1 token"}
          </p>
        </div>
        {metadata.chatGPT ? (
          <div className="GalleryItemPageDialogInfo">
            <p className="GalleryItemPageDialogInfoTitle">CHATGPT:</p>
            <p className="GalleryItemPageDialogInfoText">+1 token</p>
          </div>
        ) : null}
        {metadata.highRes ? (
          <div className="GalleryItemPageDialogInfo">
            <p className="GalleryItemPageDialogInfoTitle"> HIGH RES:</p>
            <p className="GalleryItemPageDialogInfoText">+2 tokens</p>
          </div>
        ) : null}
        <div className="GalleryItemPageDialogInfo">
          <p className="GalleryItemPageDialogInfoTitle">TOTAL:</p>
          <p className="GalleryItemPageDialogInfoText">
            {metadata.tokens > 1 ? `${metadata.tokens} tokens` : "1 token"}
          </p>
        </div>
      </Dialog>
    </div>
  ) : (
    <div className="Gallery">
      <CircularProgress size={40} sx={{ color: "#333" }} />
    </div>
  );
}

export default GalleryItem;
