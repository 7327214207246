import React, { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "../redux/reduxHooks";
import { setSettingsState, setMessageState } from "../redux/reduxSlice";

export default function MessageListeners(): JSX.Element {
  const statusState = useAppSelector((state) => state.redux.status);
  const messageState = useAppSelector((state) => state.redux.message);
  const dispatch = useAppDispatch();
  const [serversOnlineSnack, setServersOnlineSnack] = useState(false);
  const [serversFullSnack, setServersFullSnack] = useState(false);

  const checkMessages = async (messages: any) => {
    if (messageState.open === false) {
      try {
        const jsonValue = localStorage.getItem("snackbar_messages");
        let tempStorageArr = [];
        let snackArr = [];
        let storageArr = [];
        let count = 0;
        if (jsonValue !== null) {
          tempStorageArr = JSON.parse(jsonValue);
        }

        for (let i = 0; i < messages.length; i++) {
          const storeObj = tempStorageArr.find((obj: any) => {
            return obj.id === messages[i].id;
          });
          if (storeObj === undefined) {
            storageArr.push({ ...messages[i], timestamp: Date.now() });
            localStorage.setItem(
              "snackbar_messages",
              JSON.stringify(storageArr)
            );
            return dispatch(
              setMessageState({
                ...messageState,
                open: true,
                message: messages[i].text,
                duration: messages[i].duration ? null : 5000,
                background: messages[i].backgroundColor,
                messageColor: messages[i].textColor,
              })
            );
          } else if (
            storeObj.multiple === true &&
            Number(storeObj.timestamp) < Number(Date.now() - 3600000)
          ) {
            storageArr.push({ ...messages[i], timestamp: Date.now() });
            localStorage.setItem(
              "snackbar_messages",
              JSON.stringify(storageArr)
            );
            return dispatch(
              setMessageState({
                ...messageState,
                open: true,
                message: messages[i].text,
                duration: messages[i].duration ? null : 5000,
                background: messages[i].backgroundColor,
                messageColor: messages[i].textColor,
              })
            );
          }
        }
      } catch (e) {
        // error reading value
      }
    }
  };
  useEffect(() => {
    if (statusState.messages.length > 0) {
      checkMessages(statusState.messages);
    }
  }, [messageState.open, statusState.messages]);

  useEffect(() => {
    if (serversOnlineSnack === false && statusState.services.length > 0) {
      let tempBool = false;
      for (let i = 0; i < statusState.services.length; i++) {
        if (
          statusState.services[i].autoOff === false &&
          statusState.services[i].manualOff === false
        ) {
          tempBool = true;
        }
      }
      if (tempBool === false) {
        setServersOnlineSnack(true);
        dispatch(
          setMessageState({
            ...messageState,
            open: true,
            message: "All servers are offline, please try again later",
            duration: 5000,
            background: "#222",
            messageColor: "#fff",
          })
        );
      }
    }
  }, [statusState.services]);

  useEffect(() => {
    if (serversFullSnack === false && statusState.services.length > 0) {
      let tempBool = false;
      for (let i = 0; i < statusState.services.length; i++) {
        if (
          statusState.services[i].autoOff === false &&
          statusState.services[i].manualOff === false &&
          statusState.services[i].queueCount <
            statusState.services[i].queueLimit
        ) {
          tempBool = true;
        }
      }
      if (tempBool === false) {
        setServersOnlineSnack(true);
        dispatch(
          setMessageState({
            ...messageState,
            open: true,
            message: "Queue is full at the moment, please try again later",
            duration: 5000,
            background: "#222",
            messageColor: "#fff",
          })
        );
      }
    }
  }, [statusState.services]);

  return <></>;
}
